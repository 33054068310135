@font-face {
    font-family: Helvetica-Regular;
    src: url('../../../src/assets/fonts/helvetica/Helvetica-Regular.woff');
}
@font-face {
    font-family: FH-Haydon-Light;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-Light.woff');
}
@font-face {
    font-family: FH-Haydon-LightItalic;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-LightItalic.woff');
}
@font-face {
    font-family: FH-Haydon-Regular;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-Regular.woff');
}
@font-face {
    font-family: FH-Haydon-RegularItalic;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-RegularItalic.woff');
}
@font-face {
    font-family: FH-Haydon-Medium;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-Medium.woff');
}
@font-face {
    font-family: FH-Haydon-MediumItalic;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-MediumItalic.woff');
}
@font-face {
    font-family: FH-Haydon-SemiBold;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-SemiBold.woff');
}
@font-face {
    font-family: FH-Haydon-SemiBoldItalic;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-SemiBoldItalic.woff');
}
@font-face {
    font-family: FH-Haydon-Bold;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-Bold.woff');
}
@font-face {
    font-family: FH-Haydon-BoldItalic;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-BoldItalic.woff');
}
@font-face {
    font-family: FH-Haydon-Black;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-Black.woff');
}
@font-face {
    font-family: FH-Haydon-BlackItalic;
    src: url('../../../src/assets/fonts/fh-haydon/FHHaydon-BlackItalic.woff');
}
html {
    scroll-behavior: smooth;
}
body {
    background: #FFFFFF;
    color: #FFFFFF;
    font-family: "FH-Haydon-Regular";
}
p {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #000000;
}
p strong {
    font-family: "FH-Haydon-SemiBold";
}
p span {
    color: #807CE0;
}
p a {
    text-decoration: underline;
    color: #807CE0;
}
h1 {
    font-family: "FH-Haydon-Light";
    font-size: 80px;
    line-height: 90px;
    letter-spacing: 5px;
}
h2 {
    font-family: "FH-Haydon-Light";
    font-size: 60px;
    line-height: 70px;
    letter-spacing: 3px;
}
h3 {
    font-family: "FH-Haydon-Regular";
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 3.04px;
}
h4 {
    font-family: "FH-Haydon-Regular";
    font-size: 34px;
    line-height: 44px;
    letter-spacing: 1.7px;
}
h5 {
    font-family: "FH-Haydon-Regular";
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 1.6px;
}
h6 {
    font-family: "FH-Haydon-Regular";
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 2.24px;
}
.font-dark {
    color: #0A1E5B;
}
.font-dark span {
    color: #807CE0;
}
.font-light {
    color: #FFFFFF;
}
.font-light span {
    color: #FFF36E;
}
.bg-dark {
    background-color: #0A1E5B;
}
.bg-light {
    background-color: #FFFFFF;
}
.bg-grey {
    background-color: #F5F5F5;
}
.d-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
section {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
}
.container {
    max-width: 1680px;
    width: 87.5%;
    padding: 100px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.outline-btn {
    border-radius: 5px;
    position: relative;
}
.outline-btn a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 5px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.outline-btn::before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    transform-origin: 50% 50%;
    opacity: 0;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    content: "";
    border-radius: 5px;
    background-color: #807CE0;
}
.outline-btn:hover a {
    border-color: transparent !important;
}
.outline-btn:hover::before {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
}
.outline-btn.style-2::before {
    display: none;
}
.outline-btn.style-2:hover a {
    background-color: #807CE0;
}
.gradient-btn {
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    background-image: linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%);
    background-image: -webkit-linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%);
    background-image: -moz-linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%);
    background-size: 130%;
    background-position: left;
    border-radius: 5px;
    position: relative;
}
.gradient-btn:hover {
    background-position: right;
}
.gradient-btn a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    border-radius: 5px;
}
.logo-hidden {
    display: none;
}
section.legal-body .container .text-content ul, .news-single-body .container .text-content ul {
    padding-left: 20px;
}
section.legal-body .container .text-content ul li, .news-single-body .container .text-content ul li {
    padding-left: 25px;
    letter-spacing: 1px;
    color: #000000;
    position: relative;
}
section.legal-body .container .text-content ul li:not(:last-child), .news-single-body .container .text-content ul li:not(:last-child) {
    margin-bottom: 15px;
}
section.legal-body .container .text-content ul li::before, .news-single-body .container .text-content ul li::before {
    position: absolute;
    content: "";
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #DBDBDB;
}
section.legal-body .container .text-content ul li {
    font-size: 20px;
    line-height: 24px;
}
section.legal-body .container .text-content ul li::before {
    top: 5px;
}
.news-single-body .container .text-content ul li {
    font-size: 24px;
    line-height: 34px;
}
.news-single-body .container .text-content ul li::before {
    top: 10px;
}
section.legal-body .container .text-content ol, .news-single-body .container .text-content ol {
    list-style: revert;
    padding: revert;
}
section.legal-body .container .text-content ol li, .news-single-body .container .text-content ol li {
    padding-left: 15px;
    letter-spacing: 1px;
    color: #000000;
    position: relative;
}
section.legal-body .container .text-content ol li:not(:last-child), .news-single-body .container .text-content ol li:not(:last-child) {
    margin-bottom: 15px;
}
section.legal-body .container .text-content ol li {
    font-size: 20px;
    line-height: 24px;
}
section.legal-body .container .text-content ol li::before {
    top: 5px;
}
.news-single-body .container .text-content ol li {
    font-size: 24px;
    line-height: 34px;
}
.news-single-body .container .text-content ol li::before {
    top: 10px;
}
@keyframes bubbleUp {
    0% {
        opacity: 1;
        transform: translateY(0);
   }
    100% {
        opacity: 0;
        transform: translateY(-10px);
   }
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
   }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
   }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
   }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
   }
}
@-webkit-keyframes slide-top-cart {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
   }
    100% {
        -webkit-transform: translateY(7px);
        transform: translateY(7px);
   }
}
@keyframes slide-top-cart {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
   }
    100% {
        -webkit-transform: translateY(7px);
        transform: translateY(7px);
   }
}
@-webkit-keyframes slide-top-arrow {
    0% {
        -webkit-transform: translateY(0) translateX(2px);
        transform: translateY(0) translateX(2px);
   }
    100% {
        -webkit-transform: translateY(4px) translateX(2px);
        transform: translateY(4pxs) translateX(2px);
   }
}
@keyframes slide-top-arrow {
    0% {
        -webkit-transform: translateY(0) translateX(2px);
        transform: translateY(0) translateX(2px);
   }
    100% {
        -webkit-transform: translateY(4px) translateX(2px);
        transform: translateY(4px) translateX(2px);
   }
}
@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
   }
    100% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5x);
   }
}
@keyframes slide-top {
    0% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
   }
    100% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
   }
}
@keyframes zoom {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
   }
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
   }
}
@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
   }
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
   }
}
/* Fade in tabs */
@-webkit-keyframes fadeEffect {
    from {
        opacity: 0;
   }
    to {
        opacity: 1;
   }
}
@keyframes fadeEffect {
    from {
        opacity: 0;
   }
    to {
        opacity: 1;
   }
}
.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
}
.loader-wrapper .loader {
    display: block;
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 999;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 5px solid transparent;
    border-top-color: #807CE0;
    border-radius: 100%;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
.loader-wrapper .loader::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border: 5px solid transparent;
    border-top-color: #73E3E5;
    border-radius: 100%;
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    -o-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
.loader-wrapper .loader::after {
    content: "";
    position: absolute;
    top: 14px;
    left: 14px;
    right: 14px;
    bottom: 14px;
    border: 5px solid transparent;
    border-top-color: #807CE0;
    border-radius: 100%;
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    -o-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
.loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    background: #0A1E5B;
    width: 51%;
    height: 100%;
    z-index: 99;
}
.loader-wrapper .loader-section.section-left {
    left: 0;
}
.loader-wrapper .loader-section.section-right {
    right: 0;
}
body.loaded .loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.3s 1s ease-out;
    -moz-transition: all 0.3s 1s ease-out;
    -o-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;
}
body.loaded .loader-wrapper .loader {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
body.loaded .loader-wrapper .loader-section {
    -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
body.loaded .loader-wrapper .loader-section.section-left {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
}
body.loaded .loader-wrapper .loader-section.section-right {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
}
section.entry-banner {
    min-height: 80vh;
    position: relative;
    align-items: flex-end;
}
section.entry-banner::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
section.entry-banner .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    overflow: hidden;
}
section.entry-banner .background img {
    width: 100%;
    height: 100%;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    object-fit: cover;
    object-position: center;
}
section.entry-banner .container {
    z-index: 1;
}
section.entry-banner .container .text-content {
    flex-direction: column;
    max-width: 700px;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
}
section.entry-banner .container .text-content h1 {
    margin-bottom: 30px;
}
section.entry-banner .container .text-content .text {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1.2px;
}
section.entry-banner:not(.hp) .text-content {
    padding-top: 100px;
}
.header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 9999;
}
.header .container {
    padding: 0;
    margin: 0 auto;
}
.header .upper {
    width: 100%;
    padding: 8px 0 8px 0;
}
.header .upper .container {
    justify-content: flex-end;
    align-items: center;
}
.header .upper .container .user-access {
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.header .upper .container .user-access span {
    margin-left: 12px;
    font-family: "FH-Haydon-Regular";
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1.4px;
}
.header .upper .container .user-access.search {
    margin-left: 17px;
    padding: 6px 11px;
    cursor: pointer;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.header .main-header {
    width: 100%;
    z-index: 1;
}
.header .main-header .container {
    justify-content: space-between;
    align-items: center;
}
.header .main-header .container .logo {
    max-width: 190px;
    width: 190px;
    height: fit-content;
}
.header .main-header .container .logo a {
    display: block;
}
.header .main-header .container .logo a object {
    pointer-events: none;
}
.header .main-header .container .right {
    align-items: center;
}
.header .main-header .container .right > ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.header .main-header .container .right > ul > li {
    line-height: 70px;
    font-family: "FH-Haydon-Medium";
    font-size: 16px;
    letter-spacing: 1.6px;
    position: relative;
}
.header .main-header .container .right > ul > li::before {
    position: absolute;
    content: "";
    width: 0;
    height: 3px;
    left: 0;
    bottom: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.header .main-header .container .right > ul > li:not(:last-child) {
    margin-right: 30px;
}
.header .main-header .container .right > ul > li.active::before {
    width: 100%;
}
.header .main-header .container .right > ul > li.mega-menu-active::before {
    width: 100%;
}
.header .main-header .container .right > ul > li.menu-l {
    cursor: pointer;
}
.header .main-header .container .right > ul > li.menu-l a {
    margin-right: 18px;
}
.header .main-header .container .right > ul > li.menu-l::after {
    content: "";
    position: absolute;
    width: 10px;
    bottom: 0;
    right: 0;
    margin: auto 0;
    height: 6px;
    top: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-repeat: no-repeat;
}
.header .main-header .container .right .divider {
    margin: 0 32px;
    height: 33px;
    width: 1px;
}
.header .main-header .container .right .cart {
    cursor: pointer;
    position: relative;
}
.header .main-header .container .right .cart .cart-tooltip {
    position: absolute;
    top: -48px;
    width: 170px;
    left: -65px;
    padding: 10px;
    background-color: #0A1E5B;
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: 1.2px;
    text-align: center;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.header .main-header .container .right .cart .cart-tooltip::after {
    content: "";
    position: absolute;
    bottom: -4.5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 5px #0A1E5B;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
}
.header .main-header .container .right .cart .cart-count {
    position: absolute;
    width: 32px;
    top: -5px;
    right: -20px;
    height: 32px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #807CE0;
    border: 2px solid #fff;
    font-family: "FH-Haydon-Bold";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.7px;
}
.header .main-header .container .right .cart:hover .cart-tooltip {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.header .main-header .container .right .cart svg #Path_549 {
    -webkit-animation: slide-top-cart 1s linear infinite alternate-reverse both;
    animation: slide-top-cart 1s linear infinite alternate-reverse both;
}
.header .main-header .container .right .cart .cart-modal {
    position: absolute;
    right: 0;
    width: fit-content;
    min-width: 566px;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
    border-radius: 0 0 10px 10px;
    flex-direction: column;
    opacity: 0;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    pointer-events: none;
    -webkit-box-shadow: 0px 21px 23px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 21px 23px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 21px 23px 0px rgba(0, 0, 0, 0.16);
    background-color: #FFFFFF;
}
.header .main-header .container .right .cart .cart-modal .cart-body {
    flex-direction: column;
    padding: 0 40px 29px 40px;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item {
    width: 100%;
    padding: 22px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #E3E3E3;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item:hover {
    padding: 22px 15px;
    background-color: #fafafa;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item:hover .desc .title {
    color: #807CE0;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .image {
    margin-right: 21px;
    width: 63px;
    height: 63px;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    margin-right: 27px;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .title {
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 7px;
    font-family: "FH-Haydon-Medium";
    letter-spacing: 1.76px;
    color: #000000;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .category {
    font-size: 16px;
    font-family: "FH-Haydon-Light";
    line-height: 26px;
    color: #000;
    letter-spacing: 1.28px;
    margin-bottom: 5px;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .view-full {
    color: #807CE0;
    letter-spacing: 1px;
    text-decoration: underline;
    font-family: "FH-Haydon-LightItalic";
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .no-variations {
    font-family: "FH-Haydon-LightItalic";
    color: #9C9C9B;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .no-variations .icon {
    width: 20px;
    height: 20px;
    background-color: #DBDBDB;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 8px;
    color: #000;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .no-variations span {
    font-size: 16px;
    line-height: 20px;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .remove {
    cursor: pointer;
    margin-left: auto;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .remove svg g line {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.header .main-header .container .right .cart .cart-modal .cart-body .cart-item .remove:hover svg g line {
    stroke: #FF0000;
}
.header .main-header .container .right .cart .cart-modal .cart-footer {
    padding: 28px 40px;
    background-color: rgba(10, 30, 91, 0.04);
}
.header .main-header .container .right .cart .cart-modal .cart-footer button a {
    min-width: 215px;
    padding: 15px 42px;
}
.header .main-header .container .right .cart .cart-modal .cart-footer button a span {
    font-size: 16px;
    letter-spacing: 0.96px;
    line-height: 26px;
}
.header .main-header .container .right .cart .cart-modal .cart-footer button.view {
    color: #807CE0;
    margin-right: 27px;
}
.header .main-header .container .right .cart .cart-modal .cart-footer button.view a {
    border-color: #807CE0;
}
.header .main-header .container .right .cart .cart-modal .cart-footer button.view:hover {
    color: #fff;
}
.header .main-header .container .right .cart .cart-modal .cart-footer button.request a {
    color: #fff;
}
.header .main-header .container .right .cart .cart-modal .cart-footer button.request a span {
    margin-right: 10px;
}
.header .main-header .container .right .cart.cart-opened .cart-modal {
    opacity: 1;
    pointer-events: all;
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px);
    transform: translateY(15px);
}
.header .main-header .container .mobile-menu-button {
    flex-direction: column;
    display: none;
    margin-left: auto;
    margin-right: 20px;
}
.header .main-header .container .mobile-menu-button > div {
    width: 22px;
    height: 2px;
    background-color: #FFFFFF;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.header .main-header .container .mobile-menu-button > div:not(:last-child) {
    margin-bottom: 5px;
}
.header .main-header .container .mobile-menu-button > div:last-child {
    width: 16px;
}
.header.header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}
.header.header-fixed.secondary {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}
.header.header-fixed .upper {
    background-color: #807CE0;
    padding: 5px 0;
}
.header.header-fixed .main-header {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
}
.header.header-fixed .main-header .container .right .divider {
    background-color: rgba(0, 0, 0, 0.23);
}
.header.header-fixed .main-header .container .right > ul {
    color: #000000;
}
.header.header-fixed .main-header .container .right > ul > li::before {
    background-color: #807CE0;
}
.header.header-fixed .main-header .container .right > ul > li.active {
    color: #807CE0;
}
.header.header-fixed .main-header .container .right > ul > li.mega-menu-active {
    color: #807CE0;
}
.header.header-fixed .main-header .container .right > ul > li.mega-menu-active::after {
    background-image: url('../../../src/assets/icons/chevron-up-purple.svg') !important;
}
.header.header-fixed .main-header .container .right > ul .menu-l a {
    margin-right: 18px;
}
.header.header-fixed .main-header .container .right > ul .menu-l::after {
    background-image: url('../../../src/assets/icons/chevron-down-dark-sm.svg');
}
.header.header-fixed .main-header .container .mobile-menu-button > div {
    background-color: #807CE0;
}
.header.header-fixed .mega-menu.lg .container {
    max-width: 100%;
    width: 100%;
}
.header.header-fixed .mega-menu.lg .container .mega-menu-wrapper {
    border-radius: 0 0 0 0;
}
.header.banner-header {
    position: absolute;
    top: 0;
    left: 0;
}
.header.banner-header.menu-active .main-header .container {
    background-color: #807ce0;
}
.header.banner-header .upper .container .user-access.search:hover {
    background-color: #807CE0;
}
.header.banner-header .main-header {
    width: 100%;
}
.header.banner-header .main-header .container {
    padding-left: 27px;
    padding-right: 27px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    background-color: rgba(128, 124, 224, 0.85);
    border-radius: 15px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.header.banner-header .main-header .container .right .divider {
    background-color: rgba(255, 255, 255, 0.61);
}
.header.banner-header .main-header .container .right > ul > li::before {
    background-color: #0A1E5B;
}
.header.banner-header .main-header .container .right > ul > li.active {
    color: #0A1E5B;
}
.header.banner-header .main-header .container .right > ul > li.mega-menu-active {
    color: #0A1E5B;
}
.header.banner-header .main-header .container .right > ul > li.mega-menu-active::after {
    background-image: url('../../../src/assets/icons/chevron-up-dark.svg') !important;
}
.header.banner-header .main-header .container .right .menu-l a {
    margin-right: 18px;
}
.header.banner-header .main-header .container .right .menu-l::after {
    background-image: url('../../../src/assets/icons/chevron-down-light-sm.svg');
}
.header .mega-menu {
    opacity: 0;
    pointer-events: none;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 100%;
}
.header .mega-menu.active {
    opacity: 1;
    pointer-events: all;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
}
.header .mega-menu.lg .container .mega-menu-wrapper {
    border-radius: 0 0 10px 10px;
    -webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
}
.header .mega-menu.lg .container .mega-menu-wrapper .image {
    width: 25%;
    padding: 52px 38px;
    height: 100%;
    position: relative;
    align-items: flex-end;
}
.header .mega-menu.lg .container .mega-menu-wrapper .image::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -moz-linear-gradient(180deg, #807ce0 0%, #0a1e5b 100%);
    background: -webkit-linear-gradient(180deg, #807ce0 0%, #0a1e5b 100%);
    background: linear-gradient(180deg, #807ce0 0%, #0a1e5b 100%);
    opacity: 0.59;
}
.header .mega-menu.lg .container .mega-menu-wrapper .image img {
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
}
.header .mega-menu.lg .container .mega-menu-wrapper .image .text {
    z-index: 1;
    position: relative;
}
.header .mega-menu.lg .container .mega-menu-wrapper .image .text h2 {
    margin-bottom: 16px;
}
.header .mega-menu.lg .container .mega-menu-wrapper .image .text p {
    color: #FFFFFF;
}
.header .mega-menu.lg .container .mega-menu-wrapper .image .text button {
    margin-top: 27px;
}
.header .mega-menu.lg .container .mega-menu-wrapper .image .text button a {
    padding: 15px 20px;
    display: block;
    border-radius: 5px;
    background-color: #807CE0;
    font-size: 16px;
    line-height: 26px;
    font-family: "FH-Haydon-Bold";
    letter-spacing: 0.96px;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content {
    width: 75%;
    padding: 60px 50px;
    background-color: #FFFFFF;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col {
    width: 25%;
    padding: 0 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col:first-child {
    padding-left: 0;
    padding-right: 20px;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col .title {
    margin-bottom: 24.5px;
    padding-bottom: 23.3px;
    font-size: 24px;
    line-height: 34px;
    font-family: "FH-Haydon-SemiBold";
    color: #0A1E5B;
    letter-spacing: 1.2px;
    position: relative;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 37px;
    background-color: #807CE0;
    height: 1px;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li {
    position: relative;
    width: fit-content;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li:not(:last-child) {
    margin-bottom: 12px;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li a {
    color: #000000;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.8px;
    margin-right: 18px;
    display: block;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li::after {
    content: "";
    position: absolute;
    width: 15px;
    bottom: 0;
    right: 0;
    margin: auto 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    height: 14px;
    opacity: 0;
    top: 0;
    background-image: url('../../../src/assets/icons/arrow-right-purple.svg');
    background-repeat: no-repeat;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li:hover a {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
    color: #807CE0;
}
.header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li:hover::after {
    opacity: 1;
    right: -8px;
}
.header .mega-menu.sm {
    width: 300px;
    -webkit-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    top: 130%;
}
.header .mega-menu.sm ul {
    padding: 20px;
    border-radius: 5px;
    background-color: #FFFFFF;
}
.header .mega-menu.sm ul li {
    font-family: "FH-Haydon-Medium";
    letter-spacing: 1.6px;
    line-height: 26px;
    font-size: 15px;
    color: #000000;
    position: relative;
    width: fit-content;
}
.header .mega-menu.sm ul li:not(:last-child) {
    margin-bottom: 10px;
}
.header .mega-menu.sm ul li a {
    margin-right: 0 !important;
    display: block;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.header .mega-menu.sm ul li:hover a {
    -webkit-transform: translateX(7px);
    -moz-transform: translateX(7px);
    -ms-transform: translateX(7px);
    -o-transform: translateX(7px);
    transform: translateX(7px);
    color: #807CE0;
}
.mobile-nav {
    position: fixed;
    z-index: 99999;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    pointer-events: none;
    justify-content: center;
    height: 100vh;
    opacity: 0;
    width: 100%;
    background-color: #0A1E5B;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
}
.mobile-nav .nav__content {
    position: fixed;
    top: 50%;
    transform: translate(0%, -50%);
    width: 95%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    color: #fff;
}
.mobile-nav .nav__content .head {
    margin-bottom: 30px;
    position: fixed;
    width: 100%;
    z-index: 9;
}
.mobile-nav .nav__content .head .main {
    padding: 25px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background-color: #0A1E5B;
    align-items: center;
    justify-content: space-between;
}
.mobile-nav .nav__content .head .main .logo {
    max-width: 160px;
}
.mobile-nav .nav__content .head .main .logo img {
    max-width: 100%;
}
.mobile-nav .nav__content .head .main .close-mobile-m {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile-nav .nav__content .head .main .close-mobile-m > svg circle {
    stroke-dasharray: 160;
    stroke-dashoffset: 0;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
.mobile-nav .nav__content .head .main .close-mobile-m:hover > svg circle {
    stroke-dashoffset: 160;
}
.mobile-nav .nav__content > ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    padding: 120px 0;
    overflow-y: scroll;
}
.mobile-nav .nav__content > ul li.menu-l > a {
    width: 100% !important;
    cursor: pointer;
    position: relative;
}
.mobile-nav .nav__content > ul li.menu-l > a::before {
    content: "";
    position: absolute;
    width: 15px;
    bottom: 0;
    right: 10px;
    margin: auto 0;
    height: 10px;
    top: 0;
    background-image: url('../../../src/assets/icons/chevron-down-light-lg.svg');
    background-repeat: no-repeat;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.mobile-nav .nav__content > ul li.menu-l > .sub-menu {
    display: none;
    margin-top: 20px;
    background-color: rgba(10, 30, 91, 0.04);
}
.mobile-nav .nav__content > ul li.menu-l > .sub-menu ul {
    padding: revert;
}
.mobile-nav .nav__content > ul li.menu-l > .sub-menu ul a {
    padding: 5px 0;
    font-size: 22px;
    line-height: 32px;
}
.mobile-nav .nav__content > ul li.menu-l.opened > a::before {
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    transform: scaleY(-1);
}
.mobile-nav .nav__content > ul > li {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
}
.mobile-nav .nav__content > ul > li a {
    font-size: 38px;
    line-height: 48px;
    letter-spacing: 0.08em;
    display: block;
}
.mobile-nav .nav__content > ul > li:not(:last-child) {
    margin-bottom: 30px;
}
.mobile-nav .nav__content > ul > li.menu-l > .sub-menu > li:not(:last-child) {
    margin-bottom: 30px;
}
.mobile-nav .nav__content > ul > li.menu-l > .sub-menu > li > a {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 5px;
    letter-spacing: 0.05em;
    width: fit-content;
    font-family: "FH-Haydon-Light";
    padding-left: 35px;
}
.mobile-nav .nav__content > ul > li.menu-l > .sub-menu ul {
    padding: revert;
}
.mobile-nav .nav__content > ul > li.menu-l > .sub-menu ul a {
    padding: 5px 0;
    font-size: 22px;
    line-height: 32px;
}
body.nav-active {
    overflow: hidden;
}
body.nav-active .mobile-nav {
    pointer-events: all;
}
.footer {
    overflow: hidden;
}
.footer .container {
    flex-direction: column;
    position: relative;
}
.footer .container .certifications {
    align-items: center;
    width: 100%;
    z-index: 1;
    padding-bottom: 54px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.47);
    justify-content: space-between;
    margin-bottom: 117px;
}
.footer .container .certifications .left {
    padding-right: 30px;
}
.footer .container .certifications .left .title {
    font-size: 48px;
    line-height: 60px;
    font-family: "FH-Haydon-Light";
    margin-bottom: 8px;
    letter-spacing: 2.4px;
}
.footer .container .certifications .left .text {
    letter-spacing: 0.9px;
    font-size: 18px;
    line-height: 24px;
}
.footer .container .certifications .right {
    padding-left: 0px;
    width: 45%;
    flex-wrap: wrap;
}
.footer .container .certifications .right > .logo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    width: 25%;
    padding: 0 15px;
    margin-bottom: 10px;
}
.footer .container .certifications .right > .logo a {
    display: block;
}
.footer .container .certifications .right > .logo img {
    max-width: 100%;
}
.footer .container .footer-content {
    width: 100%;
    z-index: 1;
}
.footer .container .footer-content .mobile-logo {
    max-width: 180px;
    display: none;
}
.footer .container .footer-content .mobile-logo img {
    max-width: 100%;
}
.footer .container .footer-content > div:nth-child(2) {
    padding-right: 40px;
    padding-left: 0;
}
.footer .container .footer-content .col {
    width: 16.6666667%;
    padding: 0 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.footer .container .footer-content .col .logo {
    max-width: 180px;
    margin-bottom: 30px;
}
.footer .container .footer-content .col .logo img {
    max-width: 100%;
}
.footer .container .footer-content .col .legal-link {
    margin-bottom: 15px;
    padding-bottom: 7px;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1.12px;
    position: relative;
}
.footer .container .footer-content .col .legal-link::before {
    content: "";
    position: absolute;
    width: 43px;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.55);
}
.footer .container .footer-content .col .copyright {
    opacity: 0.9;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.6px;
    font-family: "FH-Haydon-Light";
    margin-top: 15px;
}
.footer .container .footer-content .col .title {
    margin-bottom: 15.5px;
    padding-bottom: 10.5px;
    position: relative;
    font-family: "FH-Haydon-Light";
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1.1px;
}
.footer .container .footer-content .col .title::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 37px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
}
.footer .container .footer-content .col ul li:not(:last-child) {
    margin-bottom: 6px;
}
.footer .container .footer-content .col ul li a {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.7px;
}
.footer .container .footer-content .col button {
    width: fit-content;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.8px;
    min-width: 180px;
}
.footer .container .footer-content .col button a {
    padding: 15px 47px;
}
.footer .container .footer-content .col button svg {
    margin-right: 12px;
}
.footer .container .footer-content .col button span {
    z-index: 1;
}
.footer .container .footer-content .col button.login {
    margin-bottom: 23px;
}
.footer .container .bg-logo {
    position: absolute;
    bottom: 0;
    left: -40px;
}
.search-modal {
    position: fixed;
    width: 100%;
    z-index: 99999;
    height: 100vh;
    opacity: 0;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    pointer-events: none;
    top: 0;
    left: 0;
    background-color: rgba(5, 16, 49, 0.75);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
}
.search-modal .container {
    margin: 0 auto;
    flex-direction: column;
}
.search-modal .container .head {
    width: 100%;
    margin-bottom: 117px;
    align-items: center;
    justify-content: space-between;
}
.search-modal .container .head .logo {
    max-width: 190px;
}
.search-modal .container .head .logo img {
    max-width: 100%;
}
.search-modal .container .head .close {
    cursor: pointer;
}
.search-modal .container .search-block {
    padding: 0;
    background-color: transparent;
}
body.search-modal-opened .search-modal {
    opacity: 1;
    pointer-events: all;
}
.search-sticky-container {
    position: sticky;
    opacity: 0;
    top: 111px;
    width: 100%;
    left: 0;
    z-index: 999;
    pointer-events: none;
    height: 203px;
}
.search-sticky-container.is-fixed .search-block {
    width: 100%;
    max-width: 100%;
    padding: 25px 0;
    border-radius: 0;
}
.search-sticky-container.is-fixed .search-block .search-container {
    width: 87.5%;
    max-width: 1680px;
}
.search-sticky-container.is-fixed .search-block .search-container form input {
    padding: 12px 63px 12px 34px;
}
.search-block {
    width: 100%;
    flex-direction: column;
    z-index: 1;
    padding: 61px 60px;
    background-color: #807CE0;
    border-radius: 10px;
}
.search-block.active .search-container .search-results {
    opacity: 1;
    pointer-events: all;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.search-block .search-container {
    width: 100%;
    position: relative;
}
.search-block .search-container form {
    position: relative;
    z-index: 1;
}
.search-block .search-container input {
    border-radius: 5px;
    width: 100%;
    background-color: #FFFFFF;
    outline: none;
    padding: 27px 63px 27px 34px;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    letter-spacing: 2px;
    font-family: "FH-Haydon-Medium";
}
.search-block .search-container input::placeholder {
    font-size: 16px;
    line-height: 21px;
    color: #9C9C9B;
    letter-spacing: 1.6px;
    font-family: "FH-Haydon-Light";
}
.search-block .search-container button.submit, .search-block .search-container button.clear {
    position: absolute;
    right: 29px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}
.search-block .search-container button.clear svg {
    width: 19x;
    height: 19px;
}
.search-block .search-container button.clear svg path {
    stroke: #0A1E5B;
}
.search-block .search-container .search-results {
    padding: 10px 52px 30px 52px;
    width: 100%;
    border-top: 2px solid #CFCFCF;
    background-color: #FFFFFF;
    border-radius: 0 0 10px 10px;
    flex-direction: column;
    position: absolute;
    opacity: 0;
    max-height: 450px;
    overflow-y: scroll;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    pointer-events: none;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
    left: 0;
    top: calc(100% - 10px);
    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.16);
}
.search-block .search-container .search-results .suggestion {
    width: 100%;
    padding: 25.5px 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.search-block .search-container .search-results .suggestion .image {
    margin-right: 35px;
    width: 78px;
    height: 78px;
}
.search-block .search-container .search-results .suggestion .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.search-block .search-container .search-results .suggestion .desc {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.search-block .search-container .search-results .suggestion .desc .title {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 3px;
    font-family: "FH-Haydon-Bold";
    letter-spacing: 1.92px;
    color: #000000;
}
.search-block .search-container .search-results .suggestion .desc .category {
    font-family: "FH-Haydon-RegularItalic";
    font-size: 18px;
    line-height: 28px;
    color: #9C9C9B;
    letter-spacing: 1.44px;
}
.search-block .search-container .search-results .suggestion:not(:last-child) {
    border-bottom: 1px solid #CFCFCF;
}
.search-block .tags-wrapper {
    margin-top: 15px;
    padding-top: 10px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
}
.search-block .tags-wrapper .label {
    margin-right: 9px;
    letter-spacing: 1.12px;
}
.search-block .tags-wrapper .tags {
    align-items: center;
}
.search-block .tags-wrapper .tags .single-tag {
    letter-spacing: 1.4px;
    font-family: "FH-Haydon-LightItalic";
    padding: 8px 15px;
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 5px;
    cursor: pointer;
}
.search-block .tags-wrapper .tags .single-tag:not(:last-child) {
    margin-right: 14px;
}
.search-block.fixed {
    width: 87.5%;
    max-width: 1680px;
    pointer-events: all;
    margin: 0 auto;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.search-block.fixed .search-container {
    margin: 0 auto;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.search-block.fixed .search-container form input {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.8px;
}
.search-block.fixed .search-container form input::placeholder {
    color: #393939;
}
.search-block.fixed .search-container .search-results {
    padding: 10px 34px;
}
.search-block.fixed .search-container .search-results .suggestion .result {
    font-family: "FH-Haydon-SemiBold";
    font-size: 18px;
    line-height: 28px;
    color: #0A1E5B;
    letter-spacing: 1.44px;
}
.search-block.fixed .search-container .search-results .suggestion:not(:last-child) {
    border-bottom: 1px solid #CFCFCF;
}
.navigation-sticky-container {
    width: 100%;
    position: sticky;
    top: 111px;
    z-index: 99;
    opacity: 0;
    height: 104px;
    pointer-events: none;
}
.navigation-sticky-container.is-sticky .navigation-wrapper {
    background-color: rgba(255, 255, 255, 0.9) !important;
    -webkit-box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.16);
    padding-top: 30px !important;
}
.navigation-sticky-container.is-sticky .navigation-wrapper .navigation {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-color: transparent;
}
.navigation-sticky-container.is-sticky .navigation-wrapper .navigation .single-navigation {
    line-height: 30px;
    padding-bottom: 15px;
    font-size: 20px;
}
.navigation-sticky-container .navigation-wrapper {
    pointer-events: all;
    padding-top: 50px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.navigation-sticky-container .navigation-wrapper .navigation {
    align-items: center;
    margin: 0 auto;
    border-bottom: 1px solid rgba(10, 30, 91, 0.46);
    max-width: 1680px;
    width: 87.5%;
}
.navigation-sticky-container .navigation-wrapper .navigation .single-navigation {
    position: relative;
    padding-bottom: 24.5px;
    font-size: 24px;
    letter-spacing: 1.92px;
    line-height: 38px;
    opacity: 0.57;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    color: #0A1E5B;
}
.navigation-sticky-container .navigation-wrapper .navigation .single-navigation:not(:last-child) {
    margin-right: 95px;
}
.navigation-sticky-container .navigation-wrapper .navigation .single-navigation::before {
    content: "";
    position: absolute;
    bottom: -2.5px;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 5px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #0A1E5B;
}
.navigation-sticky-container .navigation-wrapper .navigation .single-navigation.active {
    opacity: 1;
}
.navigation-sticky-container .navigation-wrapper .navigation .single-navigation.active::before {
    opacity: 1;
}
section.entry-banner.hp {
    min-height: 100vh;
}
section.entry-banner.hp .swiper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}
section.entry-banner.hp .swiper .swiper-wrapper {
    min-height: 100vh;
}
section.entry-banner.hp .swiper .swiper-slide {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    min-height: 100vh;
}
section.entry-banner.hp .swiper .swiper-slide .container {
    padding-bottom: 63px;
    padding-top: 150px;
    margin: 0 auto;
    min-height: 100vh;
    height: 100%;
    align-items: flex-end;
}
section.entry-banner.hp .swiper .swiper-slide .container::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
section.entry-banner.hp .swiper .swiper-slide .text-content {
    z-index: 1;
    position: relative;
    max-width: 910px;
}
section.entry-banner.hp .swiper .swiper-slide .text-content h1 {
    margin-bottom: 10px;
}
section.entry-banner.hp .swiper .swiper-slide .text-content .discover {
    margin-top: 30px;
    width: fit-content;
    margin-bottom: 125px;
    min-width: 380px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(128, 124, 224, 0.15);
    -moz-box-shadow: 0px 0px 15px 0px rgba(128, 124, 224, 0.15);
    box-shadow: 0px 0px 15px 0px rgba(128, 124, 224, 0.15);
}
section.entry-banner.hp .swiper .swiper-slide .text-content .discover a {
    align-items: center;
    padding: 20px 26px;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
}
section.entry-banner.hp .swiper .swiper-slide .text-content .discover a #Group_203 {
    -webkit-animation: slide-top-arrow 2s linear infinite alternate-reverse both;
    -moz-animation: slide-top-arrow 2s linear infinite alternate-reverse both;
    -o-animation: slide-top-arrow 2s linear infinite alternate-reverse both;
    animation: slide-top-arrow 2s linear infinite alternate-reverse both;
}
section.entry-banner.hp .swiper .swiper-slide .text-content .discover span {
    font-size: 16px;
    line-height: 26px;
    font-family: "FH-Haydon-Bold";
    letter-spacing: 0.96px;
    margin-right: 10px;
}
section.entry-banner.hp .swiper .swiper-slide > img {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
}
section.entry-banner.hp .swiper .swiper-slide > img.mobile-bg {
    display: none;
}
section.entry-banner.hp .swiper .swiper-slide-prev img, section.entry-banner.hp .swiper .swiper-slide-active img {
    animation-name: zoom;
    animation-duration: 4s;
    animation-fill-mode: forwards;
}
section.entry-banner.hp > .container {
    padding: 0;
    position: absolute;
}
section.entry-banner.hp > .container .swiper-pagination {
    position: absolute;
    bottom: 63px;
    left: 0;
    top: unset;
    width: fit-content;
}
section.entry-banner.hp > .container .swiper-pagination .swiper-pagination-bullet {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    margin-left: 0;
    margin-right: 17px;
    position: relative;
    opacity: 1;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    opacity: 0.5;
    line-height: 30px;
    color: #fff;
    background-color: transparent;
    letter-spacing: 1px;
    font-family: "FH-Haydon-Light";
}
section.entry-banner.hp > .container .swiper-pagination .swiper-pagination-bullet svg {
    position: absolute;
    left: -1px;
    top: -1px;
    -webkit-transform: rotate(-90deg) scaleY(-1);
    -moz-transform: rotate(-90deg) scaleY(-1);
    -ms-transform: rotate(-90deg) scaleY(-1);
    -o-transform: rotate(-90deg) scaleY(-1);
    transform: rotate(-90deg) scaleY(-1);
}
section.entry-banner.hp > .container .swiper-pagination .swiper-pagination-bullet svg circle {
    stroke-dasharray: 160;
    stroke-dashoffset: 160;
}
section.entry-banner.hp > .container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1;
}
section.entry-banner.hp .scroll {
    position: absolute;
    bottom: 130px;
    opacity: 0;
    z-index: 1;
    right: 45px;
    align-items: center;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
section.entry-banner.hp .scroll span {
    font-family: "FH-Haydon-Light";
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1.6px;
    margin-right: 10px;
}
section.entry-banner.hp .scroll .mouse {
    width: 39px;
    height: 16px;
    border-radius: 100px;
    border: 1px solid #FFFFFF;
    align-items: center;
    justify-content: center;
}
section.entry-banner.hp .scroll .mouse > div {
    height: 2px;
    width: 15px;
    background-color: #fff;
    -webkit-animation: slide-top 1.5s linear infinite alternate-reverse both;
    -moz-animation: slide-top 1.5s linear infinite alternate-reverse both;
    -o-animation: slide-top 1.5s linear infinite alternate-reverse both;
    animation: slide-top 1.5s linear infinite alternate-reverse both;
}
section.products .container {
    padding-top: 62px;
    flex-direction: column;
}
section.products .container .search-block {
    margin-bottom: 115px;
}
section.products .container > h1 {
    max-width: 1128px;
    margin-bottom: 78px;
    scroll-margin-top: 170px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.products .container > .categories-wrapper {
    flex-wrap: wrap;
    margin-bottom: 30px;
}
section.products .container > .categories-wrapper .single-category-wrapper {
    padding: 0 17px;
    width: 25%;
    margin-bottom: 32px;
    z-index: 1;
    -webkit-transform: translateY(60px);
    -moz-transform: translateY(60px);
    -ms-transform: translateY(60px);
    -o-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0;
}
section.products .container > .categories-wrapper .single-category-wrapper .category {
    padding: 37px 32px;
    position: relative;
    border-radius: 10px;
    min-height: 545px;
    overflow: hidden;
}
section.products .container > .categories-wrapper .single-category-wrapper .category .image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
}
section.products .container > .categories-wrapper .single-category-wrapper .category .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
section.products .container > .categories-wrapper .single-category-wrapper .category .title {
    position: absolute;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    z-index: 1;
    padding: 37px 32px;
    left: 0;
}
section.products .container > .categories-wrapper .single-category-wrapper .category .title h5 {
    font-family: "FH-Haydon-Light";
    line-height: 36px;
    letter-spacing: 1.6px;
}
section.products .container > .categories-wrapper .single-category-wrapper .category .bottom {
    position: absolute;
    left: 0;
    bottom: -100%;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    z-index: 1;
    padding: 0 32px 37px 32px;
}
section.products .container > .categories-wrapper .single-category-wrapper .category .bottom .divider {
    width: 111px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.56);
    margin-bottom: 16px;
}
section.products .container > .categories-wrapper .single-category-wrapper .category .bottom .text {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.8px;
    margin-bottom: 18px;
}
section.products .container > .categories-wrapper .single-category-wrapper .category .bottom button a {
    padding: 14px 21px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.7px;
    border-color: #fff;
}
section.products .container > .categories-wrapper .single-category-wrapper .category::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    width: 100%;
    border-radius: 10px;
    height: 100%;
    background: -moz-linear-gradient(180deg, rgba(3, 15, 52, 0) 0%, #031345 100%);
    background: -webkit-linear-gradient(180deg, rgba(3, 15, 52, 0) 0%, #031345 100%);
    background: linear-gradient(180deg, rgba(3, 15, 52, 0) 0%, #031345 100%);
}
section.products .container > .categories-wrapper .single-category-wrapper .category::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    width: 100%;
    opacity: 0;
    border-radius: 10px;
    height: 100%;
    background: -moz-linear-gradient(180deg, #807ce0 0%, #2d3a82 35%, #0a1e5b 100%);
    background: -webkit-linear-gradient(180deg, #807ce0 0%, #2d3a82 35%, #0a1e5b 100%);
    background: linear-gradient(180deg, #807ce0 0%, #2d3a82 35%, #0a1e5b 100%);
}
section.products .container > .categories-wrapper .single-category-wrapper .category:hover::after {
    opacity: 0.75;
}
section.products .container > .categories-wrapper .single-category-wrapper .category:hover::before {
    opacity: 0;
}
section.products .container > .categories-wrapper .single-category-wrapper .category:hover .title {
    top: 0 !important;
}
section.products .container > .categories-wrapper .single-category-wrapper .category:hover .bottom {
    bottom: 0;
}
section.products .container > .view-all {
    align-items: center;
}
section.products .container > .view-all .border {
    width: 100%;
    height: 1px;
    background-color: rgba(10, 30, 91, 0.4);
}
section.products .container > .view-all a {
    align-items: center;
    margin-left: 42.1px;
}
section.products .container > .view-all a span {
    margin-right: 15px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 1.28px;
    color: #0A1E5B;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.products .container > .view-all a img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.products .container > .view-all a:hover span {
    -webkit-transform: translateX(7px);
    -moz-transform: translateX(7px);
    -ms-transform: translateX(7px);
    -o-transform: translateX(7px);
    transform: translateX(7px);
}
section.products .container > .view-all a:hover img {
    -webkit-transform: translateX(3px);
    -moz-transform: translateX(3px);
    -ms-transform: translateX(3px);
    -o-transform: translateX(3px);
    transform: translateX(3px);
}
section.resources {
    scroll-margin-top: 70px;
}
section.resources .container {
    flex-direction: column;
}
section.resources .container h1 {
    margin-bottom: 23px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.resources .container > .text {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 1.2px;
    max-width: 700px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
    margin-bottom: 40px;
}
section.resources .container .resources-wrapper {
    flex-wrap: wrap;
    margin-left: -40px;
    margin-right: -40px;
    width: 100%;
}
section.resources .container .resources-wrapper .single-resource-wrapper {
    padding: 0 40px;
    height: auto;
    margin-bottom: 83px;
    -webkit-transform: translateY(60px);
    -moz-transform: translateY(60px);
    -ms-transform: translateY(60px);
    -o-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0;
    width: 33.3333333%;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource {
    height: 100%;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource > a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 37px 52px 27px;
    background-color: #807CE0;
    border-radius: 10px;
    height: 100%;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource > a > svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: calc(100% - 30px);
    bottom: 0;
    height: calc(100% - 30px);
    margin: auto;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource > a > svg g {
    width: 100%;
    height: 100%;
    stroke-dasharray: 2410;
    stroke-dashoffset: 2410;
    -webkit-transition: all 1.5s linear;
    -moz-transition: all 1.5s linear;
    -o-transition: all 1.5s linear;
    transition: all 1.5s linear;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource:hover > a > svg g {
    stroke-dashoffset: 0;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .upper {
    flex-direction: column;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .illustration {
    height: 100px;
    margin-bottom: 21px;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .illustration img {
    max-height: 100%;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource h4 {
    letter-spacing: 2.72px;
    font-family: "FH-Haydon-Medium";
    margin-bottom: 21px;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .text {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 1.1px;
    margin-bottom: 20px;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .btn {
    width: fit-content;
    margin-bottom: 5px;
    align-items: center;
    padding: 9.5px 0;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .btn span {
    font-size: 16px;
    line-height: 26px;
    font-family: "FH-Haydon-Medium";
    letter-spacing: 1.28px;
    margin-right: 9px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .btn img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    height: 1px;
    background-color: #fff;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .btn::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    height: 2px;
    background-color: #fff;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .btn:hover::after, section.resources .container .resources-wrapper .single-resource-wrapper .resource .btn:hover::before {
    width: 100%;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .btn:hover img {
    opacity: 0;
}
section.resources .container .resources-wrapper .single-resource-wrapper .resource .btn:hover span {
    -webkit-transform: translateX(8px);
    -moz-transform: translateX(8px);
    -ms-transform: translateX(8px);
    -o-transform: translateX(8px);
    transform: translateX(8px);
}
section.resources .container .cta {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 45.5px;
    border-top: 1px solid rgba(255, 255, 255, 0.26);
}
section.resources .container .cta .left {
    font-size: 25px;
    line-height: 34px;
    letter-spacing: 1.25px;
}
section.resources .container .cta button a {
    padding: 18px 53px;
    border-color: #fff;
}
section.resources .container .cta button a span {
    font-size: 16px;
    line-height: 34px;
    font-family: "FH-Haydon-Bold";
    margin-right: 7px;
    letter-spacing: 0.96px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.resources .container .cta button a img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.resources .container .cta button:hover a span {
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px);
}
section.resources .container .cta button:hover a img {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
}
section.about-section #who-we-are {
    scroll-margin-top: 170px;
}
section.about-section .container > div {
    flex-direction: column;
}
section.about-section .container > div .image-block {
    flex-direction: column;
}
section.about-section .container > div .image-block .image {
    margin-bottom: 32px;
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
}
section.about-section .container > div .image-block .image img {
    width: 100%;
    height: 100%;
    object-position: top;
    object-fit: cover;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    border-radius: 10px;
}
section.about-section .container > div .image-block h4 {
    margin-bottom: 20px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.about-section .container > div .image-block p {
    max-width: 690px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.about-section .container > div .image-block button {
    margin-top: 22px;
}
section.about-section .container > div .image-block button a {
    align-items: center;
}
section.about-section .container > div .image-block button a span {
    font-size: 14px;
    line-height: 34px;
    letter-spacing: 1.12px;
    color: #0A1E5B;
    margin-right: 5px;
}
section.about-section .container > div .image-block button a img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.about-section .container > div .image-block button:hover a img {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
}
section.about-section .container > div.left-column {
    width: 55%;
    padding-right: 70px;
}
section.about-section .container > div.left-column .image-block:first-child {
    margin-bottom: 140px;
}
section.about-section .container > div.left-column .image-block:first-child .image {
    height: 924px;
}
section.about-section .container > div.left-column .image-block:nth-child(2) {
    width: 85%;
    margin-left: auto;
}
section.about-section .container > div.left-column .image-block:nth-child(2) .image {
    height: 750px;
}
section.about-section .container > div.right-column {
    width: 45%;
    padding-left: 70px;
}
section.about-section .container > div.right-column .text-block {
    margin-bottom: 220px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.about-section .container > div.right-column .text-block h1 {
    margin-bottom: 37px;
    max-width: 350px;
}
section.about-section .container > div.right-column .image-block .image {
    height: 665px;
}
section.news-slider {
    overflow: hidden;
}
section.news-slider .container {
    flex-direction: column;
}
section.news-slider .container .text-content {
    max-width: 550px;
    margin-bottom: 44px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.news-slider .container .text-content h1 {
    margin-bottom: 36px;
}
section.news-slider .container .text-content .text {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 1.2px;
}
section.news-slider .container .swiper {
    width: 100%;
    margin-bottom: 34px;
    overflow: visible;
}
section.news-slider .container .swiper .swiper-slide {
    position: relative;
    border-radius: 10px;
    padding: 30px 29px 34px 29px;
    overflow: hidden;
}
section.news-slider .container .swiper .swiper-slide::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0.81;
    background: -moz-linear-gradient(180deg, rgba(3, 15, 52, 0) 0%, #031345 100%);
    background: -webkit-linear-gradient(180deg, rgba(3, 15, 52, 0) 0%, #031345 100%);
    background: linear-gradient(180deg, rgba(3, 15, 52, 0) 0%, #031345 100%);
}
section.news-slider .container .swiper .swiper-slide::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
    background: -moz-linear-gradient(180deg, rgba(3, 15, 52, 0.28) 0%, #031345 100%);
    background: -webkit-linear-gradient(180deg, rgba(3, 15, 52, 0.28) 0%, #031345 100%);
    background: linear-gradient(180deg, rgba(3, 15, 52, 0.28) 0%, #031345 100%);
}
section.news-slider .container .swiper .swiper-slide .bg-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 10px;
}
section.news-slider .container .swiper .swiper-slide .bg-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
section.news-slider .container .swiper .swiper-slide .inner-content {
    width: 100%;
    flex-direction: column;
    z-index: 1;
    position: relative;
    height: 100%;
    justify-content: space-between;
}
section.news-slider .container .swiper .swiper-slide .inner-content .upper {
    margin-bottom: 100px;
    width: fit-content;
}
section.news-slider .container .swiper .swiper-slide .inner-content .upper .play-video a {
    align-items: center;
}
section.news-slider .container .swiper .swiper-slide .inner-content .upper .play-video a span {
    margin-left: 11px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.8px;
}
section.news-slider .container .swiper .swiper-slide .inner-content .upper .category {
    padding: 4px;
    background-color: rgba(10, 30, 91, 0.75);
    border-radius: 5px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.8px;
}
section.news-slider .container .swiper .swiper-slide .inner-content .bottom {
    flex-direction: column;
    -webkit-transform: translateY(82px);
    -moz-transform: translateY(82px);
    -ms-transform: translateY(82px);
    -o-transform: translateY(82px);
    transform: translateY(82px);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.news-slider .container .swiper .swiper-slide .inner-content .bottom .date {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.8px;
}
section.news-slider .container .swiper .swiper-slide .inner-content .bottom .divider {
    margin: 13px 0;
    width: 43px;
    height: 1px;
    background-color: #FFFFFF;
}
section.news-slider .container .swiper .swiper-slide .inner-content .bottom p {
    color: #FFFFFF;
}
section.news-slider .container .swiper .swiper-slide .inner-content .bottom button {
    margin-top: 20.8px;
    width: fit-content;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.news-slider .container .swiper .swiper-slide .inner-content .bottom button a {
    padding: 14px 24px;
    border-color: #fff;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.7px;
}
section.news-slider .container .swiper .swiper-slide:hover::before {
    opacity: 0;
}
section.news-slider .container .swiper .swiper-slide:hover::after {
    opacity: 1;
}
section.news-slider .container .swiper .swiper-slide:hover .bg-image {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}
section.news-slider .container .swiper .swiper-slide:hover .inner-content .bottom {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
section.news-slider .container .swiper .swiper-button-next, section.news-slider .container .swiper .swiper-button-prev {
    top: -100px;
    width: 60px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
}
section.news-slider .container .swiper .swiper-button-next::after, section.news-slider .container .swiper .swiper-button-prev::after {
    display: none;
}
section.news-slider .container .swiper .swiper-button-next {
    right: 0;
}
section.news-slider .container .swiper .swiper-button-prev {
    right: 100px;
    left: unset;
}
section.news-slider .container .swiper .swiper-button-next svg, section.news-slider .container .swiper .swiper-button-prev svg {
    height: 13px;
    width: 16px;
}
section.news-slider .container .view-all {
    align-items: center;
}
section.news-slider .container .view-all .border {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.4);
}
section.news-slider .container .view-all a {
    align-items: center;
    margin-left: 42.1px;
}
section.news-slider .container .view-all a span {
    margin-right: 15px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 1.28px;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.news-slider .container .view-all a img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.news-slider .container .view-all a:hover span {
    -webkit-transform: translateX(7px);
    -moz-transform: translateX(7px);
    -ms-transform: translateX(7px);
    -o-transform: translateX(7px);
    transform: translateX(7px);
}
section.news-slider .container .view-all a:hover img {
    -webkit-transform: translateX(3px);
    -moz-transform: translateX(3px);
    -ms-transform: translateX(3px);
    -o-transform: translateX(3px);
    transform: translateX(3px);
}
section.news-slider.news-single-slider .container .swiper .swiper-button-next, section.news-slider.news-single-slider .container .swiper .swiper-button-prev {
    border: 1px solid #0A1E5B;
}
section.about-body-section .container {
    flex-direction: column;
}
section.about-body-section .container > div {
    width: 100%;
}
section.about-body-section .container > div .image {
    border-radius: 10px;
    overflow: hidden;
}
section.about-body-section .container > div .image img {
    width: 100%;
    height: 100%;
    object-position: top;
    border-radius: 10px;
    object-fit: cover;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
section.about-body-section .container > div .text-block {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.about-body-section .container > div > div {
    width: 50%;
}
section.about-body-section .container > div > div h1 {
    margin-bottom: 28px;
    max-width: 401px;
}
section.about-body-section .container > div > div .block {
    flex-direction: column;
    margin-bottom: 49px;
}
section.about-body-section .container > div > div .block > div {
    align-items: center;
}
section.about-body-section .container > div > div .block > div .divider {
    width: 35px;
    height: 1px;
}
section.about-body-section .container > div > div .block > div .color-text {
    font-size: 31px;
    line-height: 41px;
    letter-spacing: 4.65px;
    color: #807CE0;
}
section.about-body-section .container > div > div .block > div:first-child .divider {
    margin-left: 37.5px;
    background-color: #0A1E5B;
}
section.about-body-section .container > div > div .block > div:nth-child(2) .divider {
    margin-right: 37.5px;
    background-color: #807CE0;
}
section.about-body-section .container > div.upper {
    margin-bottom: 130px;
}
section.about-body-section .container > div.upper .image {
    padding-right: 35px;
    height: 924px;
}
section.about-body-section .container > div.upper .text-block {
    padding-left: 35px;
}
section.about-body-section .container > div.bottom .image {
    padding-left: 35px;
    height: 700px;
}
section.about-body-section .container > div.bottom .text-block {
    padding-right: 35px;
}
section.about-body-section .container > div.bottom .text-block h2 {
    margin-bottom: 48px;
    font-family: "FH-Haydon-Regular";
}
section.about-body-section .container > div.bottom .text-block .divider {
    width: 63px;
    height: 1px;
    background-color: #807CE0;
    margin: 22.5px 0 35.5px 0;
}
section.about-body-section .container > div.bottom .text-block p strong {
    font-size: 24px;
}
section.about-body-section .container > div.bottom .text-block button {
    margin-top: 49px;
}
section.about-body-section .container > div.bottom .text-block button a {
    padding: 20px 65px;
}
section.about-body-section .container > div.bottom .text-block button span {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.96px;
    margin-right: 30px;
}
section.team .container {
    flex-direction: column;
}
section.team .container .text-block {
    margin-bottom: 85px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.team .container .text-block h1 {
    font-family: "FH-Haydon-Regular";
    margin-bottom: 35px;
}
section.team .container .text-block .text {
    font-size: 24px;
    max-width: 550px;
    line-height: 34px;
    letter-spacing: 1.2px;
}
section.team .container .team-wrapper {
    flex-direction: column;
}
section.team .container .team-wrapper .categories {
    width: 100%;
    margin-bottom: 82px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.66);
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.team .container .team-wrapper .categories .category {
    padding-bottom: 25px;
    position: relative;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 1.92px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.team .container .team-wrapper .categories .category:not(:last-child) {
    margin-right: 100px;
}
section.team .container .team-wrapper .categories .category::before {
    content: "";
    position: absolute;
    width: 100%;
    bottom: -2.5px;
    height: 5px;
    background-color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.team .container .team-wrapper .team-members {
    width: 100%;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 50px;
    margin-left: -25px;
    margin-right: -25px;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper {
    margin-bottom: 82px;
    padding: 0 25px;
    width: 33.33333333%;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image {
    height: 595px;
    border-radius: 10px;
    margin-bottom: 25px;
    overflow: hidden;
    position: relative;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image .image-wrapper {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background-color: #0A1E5B;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    mix-blend-mode: screen;
    object-position: top;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    left: 0;
    z-index: 1;
    opacity: 0;
    top: 0;
    border-radius: 10px;
    background-image: linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%);
    background-image: -webkit-linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%);
    background-image: -moz-linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%);
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image::after {
    content: "";
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    border: 1.5px solid rgba(255, 255, 255, 0.87);
    position: absolute;
    left: 0;
    right: 0;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    top: 0;
    border-radius: 10px;
    z-index: 1;
    opacity: 0;
    bottom: 0;
    margin: auto;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image .btn-container {
    position: absolute;
    z-index: 2;
    opacity: 0;
    bottom: 42px;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    right: 42px;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image .btn-container button a {
    padding: 15px 34px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.8px;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .information {
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    max-width: 260px;
    z-index: 1;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .information .name {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 1.4px;
    font-family: "FH-Haydon-Medium";
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .information .position {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.8px;
    font-family: "FH-Haydon-LightItalic";
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member:hover .information {
    -webkit-transform: translateY(calc(-100% - 25px - 42px)) translateX(42px);
    -moz-transform: translateY(calc(-100% - 25px - 42px)) translateX(42px);
    -ms-transform: translateY(calc(-100% - 25px - 42px)) translateX(42px);
    -o-transform: translateY(calc(-100% - 25px - 42px)) translateX(42px);
    transform: translateY(calc(-100% - 25px - 42px)) translateX(42px);
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member:hover .image .image-wrapper {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member:hover .image::before {
    opacity: 0.69;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member:hover .image::after {
    opacity: 1;
}
section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member:hover .image .btn-container {
    opacity: 1;
}
section.team .container .careers-block {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.team .container .careers-block > div {
    position: relative;
}
section.team .container .careers-block .left {
    width: 47%;
    padding: 40px 63px;
    align-items: flex-end;
}
section.team .container .careers-block .left::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    opacity: 0.6;
    top: 0;
    background-image: linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%);
    background-image: -webkit-linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%);
    background-image: -moz-linear-gradient(300deg, #807CE0 0%, #73E3E5 37%, #807CE0 100%);
}
section.team .container .careers-block .left .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 100%;
}
section.team .container .careers-block .left .bg-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
section.team .container .careers-block .left h1 {
    z-index: 1;
    position: relative;
}
section.team .container .careers-block .right {
    width: 53%;
    padding: 50px 80px;
    background-color: #FFFFFF;
}
section.team .container .careers-block .right .bg-logo {
    position: absolute;
    right: 98px;
    top: -20px;
}
section.team .container .careers-block .right .careers-information {
    max-width: 335px;
    color: #0A1E5B;
}
section.team .container .careers-block .right .careers-information .title-style-1 {
    font-size: 51px;
    line-height: 51px;
    letter-spacing: 2.55px;
}
section.team .container .careers-block .right .careers-information .title-style-2 {
    font-size: 80px;
    line-height: 90px;
    letter-spacing: 4px;
    margin-bottom: 11px;
}
section.team .container .careers-block .right .careers-information p {
    font-size: 18px;
}
section.team .container .careers-block .right .careers-information button {
    margin-top: 15px;
}
section.team .container .careers-block .right .careers-information button a {
    padding: 15px 45px;
}
section.team .container .careers-block .right .careers-information button a span {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1.1px;
    margin-right: 9px;
    color: #FFFFFF;
    text-transform: capitalize;
}
section.certifications-section .container {
    flex-direction: column;
}
section.certifications-section .container .text-block {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.certifications-section .container .text-block h1 {
    margin-bottom: 23px;
}
section.certifications-section .container .text-block > p {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 1.2px;
    opacity: 0.54;
}
section.certifications-section .container .certifications-wrapper {
    flex-wrap: wrap;
    margin-top: 60px;
    margin-left: -16px;
    margin-right: -16px;
}
section.certifications-section .container .certifications-wrapper .single-certification-wrapper {
    width: 33.3333%;
    padding: 0 16px;
    height: auto;
    margin-bottom: 32px;
}
section.certifications-section .container .certifications-wrapper .single-certification-wrapper .single-certification {
    padding: 47px 36px 130px 36px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: relative;
    background-color: #807CE0;
    border-radius: 10px;
    flex-direction: column;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.certifications-section .container .certifications-wrapper .single-certification-wrapper .single-certification .ribbon {
    position: absolute;
    right: 36px;
    bottom: 30px;
}
section.certifications-section .container .certifications-wrapper .single-certification-wrapper .single-certification .logo {
    max-width: 130px;
    margin-bottom: 40px;
}
section.certifications-section .container .certifications-wrapper .single-certification-wrapper .single-certification .logo img {
    max-width: 100%;
}
section.certifications-section .container .certifications-wrapper .single-certification-wrapper .single-certification p {
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.9px;
}
.hiddenFields {
    display: none !important;
}
.additional-fields {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
section.contact-body .container {
    padding-top: 230px;
    flex-direction: column;
}
section.contact-body .container .main-content {
    margin-bottom: 100px;
}
section.contact-body .container .main-content .left {
    width: 35%;
    padding-right: 50px;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
section.contact-body .container .main-content .left h1 {
    margin-bottom: 31px;
}
section.contact-body .container .main-content .left p {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 1.2px;
}
section.contact-body .container .main-content .left .blocks {
    flex-direction: column;
    margin-top: 45px;
}
section.contact-body .container .main-content .left .blocks .block {
    min-width: 500px;
    border-radius: 10px;
}
section.contact-body .container .main-content .left .blocks .block:not(:last-child) {
    margin-bottom: 31px;
}
section.contact-body .container .main-content .left .blocks .block a {
    background-color: #0A1E5B;
    border-radius: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 33px 43px;
}
section.contact-body .container .main-content .left .blocks .block a img:not(.arrow) {
    margin-right: 36.6px;
    max-width: 45px;
    max-height: 56px;
}
section.contact-body .container .main-content .left .blocks .block a span {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 2.24px;
    margin-right: 36.6px;
}
section.contact-body .container .main-content .left .blocks .block a .arrow {
    margin-left: auto;
}
section.contact-body .container .main-content .left .blocks .block a svg:first-child {
    margin-right: 36.6px;
    max-width: 45px;
    max-height: 56px;
}
section.contact-body .container .main-content .left .blocks .block a svg:last-child {
    margin-left: auto;
}
section.contact-body .container .main-content .right {
    width: 65%;
    padding-left: 50px;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
section.contact-body .container .main-content .right .form-wrapper {
    width: 100%;
    padding: 61px 84px 90px 84px;
    border-radius: 10px;
    background-color: #0A1E5B;
    flex-direction: column;
}
section.contact-body .container .main-content .right .form-wrapper h2 {
    font-family: "FH-Haydon-Regular";
    margin-bottom: 30px;
    letter-spacing: 4.8px;
}
section.contact-body .container .main-content .right .form-wrapper p {
    font-size: 18px;
    color: #FFFFFF;
}
section.contact-body .container .main-content .right .form-wrapper form {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 31px;
}
section.contact-body .container .main-content .right .form-wrapper form .input-block {
    margin-bottom: 43px;
}
section.contact-body .container .main-content .right .form-wrapper form .input-block:not(.full) {
    width: 45%;
}
section.contact-body .container .main-content .right .form-wrapper form .input-block.full {
    width: 100%;
}
section.contact-body .container .main-content .right .form-wrapper form .input-block label {
    font-size: 18px;
    line-height: 29px;
    font-family: "FH-Haydon-Medium";
    letter-spacing: 0.9px;
}
section.contact-body .container .main-content .right .form-wrapper form .input-block input, section.contact-body .container .main-content .right .form-wrapper form .input-block textarea, section.contact-body .container .main-content .right .form-wrapper form .input-block select {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.28px;
    margin-top: 5px;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    background-color: #fff;
    resize: none;
    outline: none;
    color: #0A1E5B;
}
section.contact-body .container .main-content .right .form-wrapper form .input-block input::placeholder, section.contact-body .container .main-content .right .form-wrapper form .input-block textarea::placeholder, section.contact-body .container .main-content .right .form-wrapper form .input-block select::placeholder {
    opacity: 0.83;
    color: #0A1E5B;
}
section.contact-body .container .main-content .right .form-wrapper form .input-block select {
    appearance: none;
    background-image: url("../../../src/assets/icons/chevron-down-dark-bg.svg");
    background-repeat: no-repeat;
    background-position: right 15px top 50%;
    background-size: 23px;
}
section.contact-body .container .main-content .right .form-wrapper form .consent {
    opacity: 0.6;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.8px;
    margin-bottom: 35px;
}
section.contact-body .container .main-content .right .form-wrapper form .outline-btn a {
    padding: 20px 60px;
}
section.contact-body .container .main-content .right .form-wrapper form .outline-btn a span {
    margin-right: 25px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.96px;
}
section.contact-body .container .map {
    width: 100%;
    height: 800px;
    border-radius: 10px;
}
section.contact-body .container .map iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    opacity: 0;
}
section.contact-details .container {
    flex-direction: column;
}
section.contact-details .container h2 {
    margin-bottom: 40px;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
section.contact-details .container .locations {
    flex-wrap: wrap;
}
section.contact-details .container .locations .single-detail-wrapper {
    width: 25%;
}
section.contact-details .container .locations .single-detail-wrapper .single-detail {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.contact-details .container .locations .single-detail-wrapper .single-detail > a {
    background-color: #213A87;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    padding: 51px 41px 62px 41px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    height: 100%;
}
section.contact-details .container .locations .single-detail-wrapper .single-detail > a:hover {
    background-color: #807CE0;
}
section.contact-details .container .locations .single-detail-wrapper .single-detail > a:hover p {
    opacity: 1;
}
section.contact-details .container .locations .single-detail-wrapper .single-detail p {
    opacity: 0.7;
    word-break: break-word;
    text-align: center;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.contact-details .container .contact-info {
    flex-wrap: wrap;
}
section.contact-details .container .contact-info .single-detail-wrapper {
    width: 33.3333%;
}
section.contact-details .container .contact-info .single-detail-wrapper .single-detail {
    padding: 66px 52px 62px 52px;
    opacity: 0;
    background-color: #213A87;
    border-radius: 10px;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
section.contact-details .container .contact-info .single-detail-wrapper .single-detail h6 {
    margin-bottom: 32px;
}
section.contact-details .container .contact-info .single-detail-wrapper .single-detail p {
    opacity: 0.79;
    word-break: break-word;
}
section.contact-details .container .single-detail-wrapper {
    margin-bottom: 48px;
    padding: 0 24px;
    height: auto;
}
section.contact-details .container .single-detail-wrapper .single-detail {
    height: 100%;
}
section.contact-details .container .single-detail-wrapper .single-detail .divider {
    margin: 25.5px 0;
    width: 119px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.52);
}
section.contact-details .container .single-detail-wrapper .single-detail h6 {
    letter-spacing: 1.4px;
    font-family: "FH-Haydon-Medium";
}
section.contact-details .container .single-detail-wrapper .single-detail p {
    font-size: 26px;
    line-height: 36px;
    color: #fff;
    font-family: "FH-Haydon-Light";
}
section.contact-details .container .single-detail-wrapper .single-detail p a {
    color: #fff;
    text-decoration: none;
}
section.faqs-body {
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
}
section.faqs-body .faqs-wrapper {
    flex-direction: column;
    margin-top: 80px;
    max-width: 1680px;
    width: 87.5%;
    margin-bottom: 87px;
}
section.faqs-body .faqs-wrapper .faq-item {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 30px 60px;
}
section.faqs-body .faqs-wrapper .faq-item.opened .faq-item__question .circle .plus-minus > div:last-child {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
section.faqs-body .faqs-wrapper .faq-item:not(:last-child) {
    margin-bottom: 40px;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__question {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__question .question {
    padding-right: 15px;
    font-size: 38px;
    line-height: 48px;
    color: #0A1E5B;
    letter-spacing: 1.9px;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__question .circle {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #807CE0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__question .circle .plus-minus {
    width: 23px;
    height: 23px;
    position: relative;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__question .circle .plus-minus > div {
    position: absolute;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #FFFFFF;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__question .circle .plus-minus > div:first-child {
    width: 23px;
    height: 2px;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__question .circle .plus-minus > div:last-child {
    left: 50%;
    top: 0;
    width: 2px;
    height: 23px;
    -webkit-transform: translateX(-50%) rotate(180deg);
    -moz-transform: translateX(-50%) rotate(180deg);
    -ms-transform: translateX(-50%) rotate(180deg);
    -o-transform: translateX(-50%) rotate(180deg);
    transform: translateX(-50%) rotate(180deg);
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer {
    margin-top: 43.7px;
    padding-top: 38.5px;
    display: none;
    border-top: 1px solid rgba(10, 30, 91, 0.51);
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 100%;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-body {
    margin-bottom: 34px;
    width: 100%;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer .keywords {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.7px;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer .keywords .text {
    margin-bottom: 22.3px;
    color: #0A1E5B;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer .keywords .tags-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer .keywords .tags-wrapper .tag {
    margin-bottom: 14px;
    padding: 8px 15px;
    color: #807CE0;
    letter-spacing: 1.4px;
    border-radius: 5px;
    background-color: rgba(128, 124, 224, 0.12);
    font-family: "FH-Haydon-LightItalic";
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer .keywords .tags-wrapper .tag:not(:last-child) {
    margin-right: 14px;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer button {
    height: fit-content;
}
section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer button a {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.96px;
    padding: 15px 35px;
}
section.faqs-body .cta {
    max-width: 1680px;
    width: 87.5%;
    justify-content: space-between;
    padding-top: 56px;
    border-top: 1px solid #D1D1D1;
    align-items: center;
}
section.faqs-body .cta .text {
    padding-right: 40px;
    color: #0A1E5B;
    font-size: 25px;
    line-height: 34px;
    letter-spacing: 1.25px;
}
section.faqs-body .cta button a {
    border-color: #807CE0;
    padding: 20px 53px;
}
section.faqs-body .cta button a span {
    color: #807CE0;
    font-size: 16px;
    margin-right: 7px;
    line-height: 24px;
    letter-spacing: 0.96px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    font-family: "FH-Haydon-Bold";
}
section.faqs-body .cta button a svg {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.faqs-body .cta button a svg path, section.faqs-body .cta button a svg line {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.faqs-body .cta button:hover a span {
    color: #fff;
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px);
}
section.faqs-body .cta button:hover a svg {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
}
section.faqs-body .cta button:hover a svg #Icon_metro-chevron-thin-right, section.faqs-body .cta button:hover a svg #circle-path {
    fill: #fff;
}
section.faqs-body .cta button:hover a svg line {
    stroke: #fff;
}
section.solutions-body {
    flex-direction: column;
    position: relative;
    align-items: center;
}
section.solutions-body .navigation-wrapper {
    background-color: rgba(255, 255, 255, 0.36);
    -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
}
section.solutions-body .container {
    flex-direction: column;
    padding-top: 0;
}
section.solutions-body .container .solutions-wrapper {
    flex-direction: column;
    margin-top: 90px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    scroll-margin-top: 230px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper:not(:last-child) {
    margin-bottom: 130px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper .image-block {
    width: 60%;
    overflow: hidden;
    height: 100%;
    border-radius: 10px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper .image-block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    border-radius: 10px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block {
    width: 40%;
    padding: 50px 0;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block h1 {
    margin-bottom: 20px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block button {
    margin-top: 40px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block button a {
    padding: 18px 65px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block button a span {
    margin-right: 20px;
    font-size: 16px;
    letter-spacing: 0.96px;
    line-height: 24px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(odd) .text-block {
    padding-right: 60px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(odd) .image-block {
    padding-left: 60px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(even) {
    flex-direction: row-reverse;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(even) .text-block {
    padding-left: 60px;
}
section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(even) .image-block {
    padding-right: 60px;
}
section.technical-body {
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    position: relative;
}
section.technical-body .navigation-sticky-container {
    top: 205px;
}
section.technical-body .navigation-sticky-container .navigation-wrapper {
    background-color: rgba(255, 255, 255, 0.08);
    padding-top: 40px;
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
}
section.technical-body section.data {
    width: 100%;
    scroll-margin-top: 260px;
}
section.technical-body section.data .container {
    flex-direction: column;
}
section.technical-body section.data .container p {
    font-size: 18px;
    opacity: 0.74;
    letter-spacing: 0.9px;
    margin-top: 13px;
    max-width: 730px;
}
section.technical-body section.data .container .data-wrapper {
    flex-wrap: wrap;
    margin-top: 40px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper .single-data {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    border-radius: 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper .single-data button {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    height: fit-content;
    pointer-events: none;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    margin: auto;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: fit-content;
    right: 0;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper .single-data button a {
    border-radius: 5px;
    color: #0A1E5B;
    letter-spacing: 1.08px;
    font-size: 18px;
    line-height: 20px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper .single-data:hover button {
    pointer-events: all;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data {
    width: 20%;
    padding: 0 15px;
    margin-bottom: 30px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data {
    background-color: #fff;
    padding: 71px 25px 20px !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data svg {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data svg path {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data .divider {
    margin: 36px 0;
    width: 72px;
    height: 1px;
    background-color: #807CE0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data .title {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1.4px;
    font-family: "FH-Haydon-SemiBold";
    color: #0A1E5B;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data button a {
    padding: 15px 50px;
    background-color: #F5F5F5;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data:hover .title {
    color: #fff;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data:hover .divider {
    width: 109px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data {
    width: 25%;
    padding: 0 25px;
    margin-bottom: 50px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data {
    overflow: hidden;
    align-items: flex-end;
    min-height: 450px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    z-index: 1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data .icon {
    position: absolute;
    z-index: 2;
    top: 29px;
    right: 19px;
    cursor: pointer;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data .icon > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0A1E5B;
    border-radius: 50%;
    width: 57px;
    height: 57px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data .bg-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data .bg-image img {
    width: 100%;
    height: 100%;
    object-position: top;
    object-fit: cover;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data button {
    z-index: 2;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data button a {
    padding: 15px 35px;
    background-color: #fff;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data .title-wrapper {
    z-index: 2;
    height: fit-content;
    padding: 30px 36px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data .title-wrapper .title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1.2px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data:hover .title-wrapper {
    background-color: transparent !important;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data:hover::before {
    height: 100%;
}
section.technical-body section.data .container .btn-wrapper {
    width: 100%;
    margin-top: 45px;
    justify-content: flex-end;
}
section.technical-body section.data .container .btn-wrapper button a span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.96px;
    margin-right: 15px;
    white-space: nowrap;
}
section.technical-body section.data .container .btn-wrapper button:first-child {
    margin-right: 30px;
}
section.technical-body section.data .container .btn-wrapper .outline-btn a {
    padding: 18px 30px;
}
section.technical-body section.data .container .btn-wrapper .outline-btn.hover span {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.technical-body section.data .container .btn-wrapper .outline-btn.hover img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
section.technical-body section.data .container .btn-wrapper .outline-btn.hover:hover a span {
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px);
}
section.technical-body section.data .container .btn-wrapper .outline-btn.hover:hover a img {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
}
section.technical-body section.data .container .btn-wrapper .gradient-btn a {
    padding: 18px 40px;
}
section.technical-body section.data.bg-grey .container .data-wrapper .single-data-wrapper.technical-data .single-data:hover {
    background-color: #0A1E5B;
}
section.technical-body section.data.bg-grey .container .data-wrapper .single-data-wrapper.technical-data .single-data:hover svg {
    opacity: 0.18;
}
section.technical-body section.data.bg-grey .container .data-wrapper .single-data-wrapper.literature-data .single-data .title-wrapper {
    background-color: #0A1E5B;
}
section.technical-body section.data.bg-grey .container .data-wrapper .single-data-wrapper.literature-data .single-data::before {
    background-color: rgba(10, 30, 91, 0.71);
}
section.technical-body section.data.bg-grey .container .btn-wrapper .outline-btn a {
    border-color: #0A1E5B;
}
section.technical-body section.data.bg-grey .container .btn-wrapper .outline-btn a span {
    color: #0A1E5B;
}
section.technical-body section.data.bg-dark .container .data-wrapper .single-data-wrapper.technical-data .single-data:hover {
    background-color: #807CE0;
}
section.technical-body section.data.bg-dark .container .data-wrapper .single-data-wrapper.technical-data .single-data:hover svg {
    opacity: 0.31;
}
section.technical-body section.data.bg-dark .container .data-wrapper .single-data-wrapper.technical-data .single-data:hover svg path {
    fill: #fff;
}
section.technical-body section.data.bg-dark .container .data-wrapper .single-data-wrapper.technical-data .single-data:hover .divider {
    background-color: rgba(255, 255, 255, 0.44);
}
section.technical-body section.data.bg-dark .container .data-wrapper .single-data-wrapper.literature-data .single-data .title-wrapper {
    background-color: #807CE0;
}
section.technical-body section.data.bg-dark .container .data-wrapper .single-data-wrapper.literature-data .single-data::before {
    background-color: rgba(128, 124, 224, 0.81);
}
section.technical-body section.data.bg-dark .container .btn-wrapper .outline-btn a {
    border-color: #fff;
}
.subscription-download-form {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    opacity: 0;
    pointer-events: none;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    background-color: rgba(0, 0, 0, 0.6);
}
.subscription-download-form .container {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    height: 100%;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
}
.subscription-download-form .container .form-wrapper {
    flex-direction: column;
    padding: 74px 82px 97px 82px;
    background-color: #FFFFFF;
    border-radius: 10px;
    position: relative;
    max-width: 700px;
}
.subscription-download-form .container .form-wrapper .close {
    position: absolute;
    top: 24px;
    cursor: pointer;
    right: 24px;
}
.subscription-download-form .container .form-wrapper .title {
    font-size: 24px;
    line-height: 32px;
    color: #0A1E5B;
    opacity: 0.54;
    letter-spacing: 1.2px;
    margin-bottom: 25px;
}
.subscription-download-form .container .form-wrapper form {
    flex-wrap: wrap;
    justify-content: space-between;
}
.subscription-download-form .container .form-wrapper form .input-block {
    margin-bottom: 34px;
}
.subscription-download-form .container .form-wrapper form .input-block:not(.full) {
    width: 45%;
}
.subscription-download-form .container .form-wrapper form .input-block.full {
    width: 100%;
}
.subscription-download-form .container .form-wrapper form .input-block label {
    margin-bottom: 12px;
}
.subscription-download-form .container .form-wrapper form .input-block input {
    background-color: rgba(210, 210, 210, 0.5);
    border-radius: 5px;
    width: 100%;
    padding: 13px;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.28px;
    color: #0A1E5B;
}
.subscription-download-form .container .form-wrapper form label {
    font-size: 18px;
    line-height: 29px;
    font-family: "FH-Haydon-Medium";
    color: #0A1E5B;
    letter-spacing: 0.9px;
}
.subscription-download-form .container .form-wrapper form .terms {
    width: 55%;
}
.subscription-download-form .container .form-wrapper form .terms input {
    width: 15px;
    height: 15px;
    margin-top: 5px;
}
.subscription-download-form .container .form-wrapper form .terms label {
    margin-left: 10px;
    font-size: 16px;
    font-family: "FH-Haydon-Regular";
    line-height: 24px;
}
.subscription-download-form .container .form-wrapper form button a {
    padding: 18px 55px;
}
.subscription-download-form .container .form-wrapper form button a span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.96px;
    margin-right: 11px;
}
body.download-modal-open .subscription-download-form {
    opacity: 1;
    pointer-events: all;
}
body.download-modal-open .subscription-download-form .container {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.catalog-body {
    background-color: #F5F5F5;
    position: relative;
}
.catalog-body::before {
    width: 30%;
    background-color: #0A1E5B;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    z-index: 0;
    height: 100%;
}
.catalog-body .container {
    padding: 0;
}
.catalog-body .container .sidebar {
    width: 30%;
    opacity: 0;
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
    padding: 200px 83px 200px 0;
    max-width: 480px;
    flex-direction: column;
}
.catalog-body .container .sidebar .action {
    margin-bottom: 44px;
}
.catalog-body .container .sidebar .action form {
    position: relative;
    margin-bottom: 32px;
}
.catalog-body .container .sidebar .action form input {
    width: 100%;
    background-color: #FFFFFF;
    outline: none;
    border-radius: 5px;
    font-size: 16px;
    line-height: 29px;
    color: #0A1E5B;
    padding: 20px 50px 20px 24px;
    letter-spacing: 0.8px;
}
.catalog-body .container .sidebar .action form input::placeholder {
    opacity: 0.66;
}
.catalog-body .container .sidebar .action form button.submit, .catalog-body .container .sidebar .action form button.clear {
    position: absolute;
    right: 26px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}
.catalog-body .container .sidebar .action form button.clear svg {
    width: 19x;
    height: 19px;
}
.catalog-body .container .sidebar .action form button.clear svg path {
    stroke: #0A1E5B;
}
.catalog-body .container .sidebar .action form button.submit img {
    max-width: 20px;
}
.catalog-body .container .sidebar .action .btn-wrapper button {
    border-radius: 5px;
    background-color: #807CE0;
}
.catalog-body .container .sidebar .action .btn-wrapper button:first-child {
    margin-right: 19px;
}
.catalog-body .container .sidebar .action .btn-wrapper button a {
    padding: 15px 24px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.catalog-body .container .sidebar .action .btn-wrapper button a span {
    margin-left: 12px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1.6px;
}
.catalog-body .container .sidebar .category-block-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block:not(:last-child) {
    margin-bottom: 40px;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block > .head {
    width: 100%;
    justify-content: space-between;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding-bottom: 23.5px;
    align-items: center;
    border-bottom: 1px solid rgba(151, 151, 151, 0.6);
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block > .head .title {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 1.92px;
    padding-right: 20px;
    margin-left: 16px;
}
.catalog-body .container .sidebar .category-block-wrapper>.category-blocks>.category-block>.head input {
    width: 27px;
    min-width: 27px;
    height: 27px;
    accent-color: #807CE0;
    border-radius: 4px;
}
.catalog-body .container .sidebar .category-block-wrapper>.category-blocks>.category-block .body input {
    min-width: 23px;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block > .head .icon > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 1px solid transparent;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #fff;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block > .head .icon > div svg {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block > .head .icon > div svg path {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .body {
    display: none;
    width: 100%;
    margin-top: 27px;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .body:has(input[type="checkbox"]:checked) {
    display: block;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .body input {
    width: 23px;
    height: 23px;
    accent-color: #807CE0;
    border-radius: 4px;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .body label {
    margin-left: 13px;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1.44px;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .body .category {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .body .category:not(:last-child) {
    margin-bottom: 19px;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .body label {
    pointer-events: none;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block.opened .head .icon > div {
    background-color: transparent;
    border-color: #fff;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block.opened .head .icon > div svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block.opened .head .icon > div svg path {
    stroke: #fff;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .head {
    cursor: pointer;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks .category-block.sub-category:not(:last-child) {
    margin-bottom: 40px;
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks .category-block.sub-category .head {
    justify-content: space-between;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.catalog-body .container .sidebar .category-block-wrapper>.category-blocks .category-block.sub-category .head .circle>div {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #07153f;
}
.catalog-body .container .sidebar .category-block-wrapper>.category-blocks .category-block.sub-category .head .circle>div .plus-minus {
    width: 12px;
    height: 12px;
    position: relative;
}
.catalog-body .container .sidebar .category-block-wrapper>.category-blocks .category-block.sub-category .head .circle>div .plus-minus>div {
    position: absolute;
    background-color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.catalog-body .container .sidebar .category-block-wrapper>.category-blocks .category-block.sub-category .head .circle>div .plus-minus>div:first-child {
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 12px;
    height: 1px;
}
.catalog-body .container .sidebar .category-block-wrapper>.category-blocks .category-block.sub-category .head .circle>div .plus-minus>div:nth-child(2) {
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%) rotate(180deg);
    -moz-transform: translateX(-50%) rotate(180deg);
    -ms-transform: translateX(-50%) rotate(180deg);
    -o-transform: translateX(-50%) rotate(180deg);
    transform: translateX(-50%) rotate(180deg);
    width: 1px;
    height: 12px;
}
.catalog-body .container .sidebar .category-block-wrapper>.category-blocks .category-block.sub-category.opened .head .circle>div .plus-minus>div:nth-child(2) {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform:rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.catalog-body .container .sidebar .category-block-wrapper > .category-blocks .category-block.sub-category .body {
    padding-left: 30px;
}
.catalog-body .container .sidebar h5 {
    margin-bottom: 42px;
    opacity: 0.6;
}
.catalog-body .container .main-content {
    width: 70%;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    background-color: #F5F5F5;
    padding: 200px 0 200px 60px;
    flex-direction: column;
}
.catalog-body .container .main-content > h1 {
    max-width: 663px;
    margin-bottom: 24px;
}
.catalog-body .container .main-content > .text {
    max-width: 663px;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 1.2px;
    color: #0A1E5B;
    margin-bottom: 38px;
}
.catalog-body .container .main-content .catalog-category-custom-margin h1 {
    max-width: 100%;
    margin-bottom: 15px;
}
.catalog-body .container .main-content .catalog-category-custom-margin .main-text {
    max-width: 100%;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #0A1E5B;
    margin-bottom: 0;
}
.catalog-body .container .main-content .selected-filters-wrapper {
    padding-top: 0;
    position: sticky;
    top: 111px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 9;
    flex-direction: column;
}
.catalog-body .container .main-content .selected-filters-wrapper .sf-title {
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 1.4px;
    color: #0A1E5B;
    margin-bottom: 8px;
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.catalog-body .container .main-content .selected-filters {
    margin-bottom: 0px;
    align-items: center;
    flex-wrap: wrap;
}
.catalog-body .container .main-content .selected-filters .selected-filter {
    margin-bottom: 10px;
    padding: 8px 15px;
    background-color: #807CE0;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.catalog-body .container .main-content .selected-filters .selected-filter:not(:last-child) {
    margin-right: 10px;
}
.catalog-body .container .main-content .selected-filters .selected-filter span {
    font-family: "FH-Haydon-LightItalic";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.12px;
    margin-right: 10px;
}
.catalog-body .container .main-content .selected-filters-wrapper.is-fixed {
    background-color: #F5F5F5;
    padding-top: 10px;
}
.catalog-body .container .main-content .selected-filters-wrapper.is-fixed .sf-title {
    opacity: 1;
}
.catalog-body .container .main-content .selected-filters .view-more .view-more-body-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}
.catalog-body .container .main-content .selected-filters .view-more .view-more-btn {
    color: #807CE0;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1.2px;
    margin-bottom: 5px;
    cursor: pointer;
    display: none;
    text-decoration: underline;
}
.catalog-body .container .main-content .catalog-category-custom-margin .category-level-two {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
    flex-direction: column;
    margin-top: 30px;
}
.catalog-body .container .main-content .catalog-category-custom-margin .category-level-two h3 {
    margin-bottom: 20px;
}
.catalog-body .container .main-content .catalog-category-custom-margin .category-level-two .cl2-text {
    font-size: 20px;
    line-height: 30px;
    color: #0A1E5B;
    margin-bottom: 22px;
    letter-spacing: 1px;
}
.catalog-body .container .catalog-category-custom-margin:not(:first-child) {
    margin-top: 70px;
}
.catalog-body .container .catalog-category-custom-margin {
    position: relative;
}
.catalog-body .container .main-content .selected-filters .selected-filter .remove-filter {
    width: 12px;
    cursor: pointer;
}
.catalog-body .container .main-content .subcategories-blocks {
    flex-direction: column;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .head h6 {
    font-size: 24px;
    line-height: 34px;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block:first-child .body {
    display: block;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block:not(:last-child) {
    margin-bottom: 20px;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .head {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .head .upper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .head .upper .line {
    width: 100%;
    height: 1px;
    background-color: #DEDEDE;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .head .upper .icon {
    margin-left: 22px;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .head .upper .icon > div {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFEFEF;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .head .upper .icon > div img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body {
    margin-top: 19px;
    display: none;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body > .description {
    margin-bottom: 32px;
    max-width: 910px;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    opacity: 0.52;
    letter-spacing: 0.54px;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper {
    width: 25%;
    padding: 0 12.5px;
    margin-bottom: 25px;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product {
    border-radius: 10px;
    background-color: rgba(10, 30, 91, 0.25);
    padding: 24px 34px;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    flex-direction: column;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .product-image {
    max-width: 150px;
    height: 150px;
    margin: auto;
    margin-bottom: 20px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .product-image img {
    max-width: 100%;
    max-height: 100%;
    mix-blend-mode: multiply;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .title {
    font-size: 20px;
    color: #0A1E5B;
    line-height: 30px;
    letter-spacing: 1.6px;
    font-family: "FH-Haydon-Bold";
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .actions {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    flex-direction: column;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    align-items: center;
    justify-content: center;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .actions button {
    border-radius: 5px;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .actions button:first-child {
    margin-bottom: 19px;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .actions button a {
    padding: 12px 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .actions button a span {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.84px;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .actions button.view {
    border: 1px solid #FFFFFF;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .actions button.add {
    background-color: #0A1E5B;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product:hover {
    background-color: #807CE0;
}
/* .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product:hover .product-image {
    opacity: 0.32;
}
*/
.catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product:hover .actions {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block.opened .head .upper .icon img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.catalog-body .container .main-content .subcategories-blocks .subcategory-block.opened .head .upper .icon svg {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.catalog-body .container .main-content .empty-product-list {
    padding-left: 80px;
    text-align: center;
}

.catalog-body .container .main-content .empty-product-list h3 {
    margin-top: 40px;
    color: #0A1E5B;
}

.catalog-body .container .main-content .empty-product-list p {
    margin-top: 5px;
    color: #0A1E5B;
    font-size: 14px;
    letter-spacing: 0.1px;
}


.product-breadcrumb {
    margin-bottom: 30px;
    align-items: baseline !important;
}
.product-breadcrumb .icon {
    margin-right: 16px;
}
.product-breadcrumb .icon img {
    vertical-align: baseline;
}
.product-breadcrumb .text {
    align-items: center;
    flex-wrap: wrap;
}
.product-breadcrumb .text div {
    color: #807CE0;
}
.product-breadcrumb .text div:not(:last-child) {
    margin-right: 13px;
}
.product-breadcrumb .text div.arrow img {
    vertical-align: middle;
}
.product-breadcrumb .text div:not(.arrow) {
    line-height: 29px;
    font-size: 16px;
    letter-spacing: 0.8px;
}
.product-breadcrumb .text div:last-child {
    color: #0A1E5B;
    opacity: 0.49;
}
.product-breadcrumb svg{
    vertical-align: middle;
}
.single-product-upper {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
.single-product-upper .container {
    padding-top: 180px;
    flex-direction: column;
    padding-bottom: 0;
}
.single-product-body {
    flex-direction: column;
    position: relative;
}
.single-product-body .navigation-sticky-container .navigation-wrapper {
    background-color: rgba(255, 255, 255, 0.36);
    -webkit-backdrop-filter: blur(19px);
    backdrop-filter: blur(19px);
    padding-top: 40px;
}
.single-product-body section {
    scroll-margin-top: 200px;
}
.single-product-body section .cta {
    justify-content: space-between;
    width: 100%;
}
.single-product-body section .cta button {
    height: fit-content;
}
.single-product-body section .cta button a {
    padding: 18px 38px;
}
.single-product-body section .cta button a span {
    margin-right: 16px;
    font-size: 16px;
    white-space: nowrap;
    line-height: 26px;
    letter-spacing: 0.96px;
}
.single-product-body section.overview .container {
    padding-top: 72px;
    position: relative;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
.single-product-body section.overview .container .product-image-zoom {
    position: absolute;
    width: 818px;
    z-index: 99;
    pointer-events: none;
    -webkit-transform: scale(0.2);
    -moz-transform: scale(0.2);
    -ms-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
    height: 100%;
    top: 0;
    left: 0;
}
.single-product-body section.overview .container .product-image-zoom.opened {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
}
.single-product-body section.overview .container .product-image-zoom .product-image-wrapper {
    max-width: 100%;
    height: 98%;
    width: 100%;
    max-height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    align-items: center;
    position: relative;
    justify-content: center;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
}
.single-product-body section.overview .container .product-image-zoom .product-image-wrapper img.product-image {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
}
.single-product-body section.overview .container .product-image-zoom .product-image-wrapper .zoom-out {
    position: absolute;
    top: 45px;
    right: 40px;
    cursor: pointer;
}
.single-product-body section.overview .container .gallery {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-right: 11px;
    height: 400px;
    width: 31%;
    position: relative;
    min-width: 31%;
}
.single-product-body section.overview .container .gallery .swiper {
    width: 100%;
    height: 100%;
}
.single-product-body section.overview .container .gallery .swiper .swiper-slide {
    border-radius: 5px;
    overflow: hidden;
}
.single-product-body section.overview .container .gallery .swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.single-product-body section.overview .container .gallery .swiper-button-next, .single-product-body section.overview .container .gallery .swiper-button-prev {
    left: 10%;
    right: unset;
    color: #807CE0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.single-product-body section.overview .container .gallery .swiper-button-next::after, .single-product-body section.overview .container .gallery .swiper-button-prev::after {
    font-size: 32px;
}
.single-product-body section.overview .container .gallery .swiper-button-next {
    bottom: -50px;
    top: unset;
}
.single-product-body section.overview .container .gallery .swiper-button-prev {
    bottom: unset;
    top: -30px;
}
.single-product-body section.overview .container .gallery .main-slider {
    height: 100%;
    width: 75%;
    position: relative;
}
.single-product-body section.overview .container .gallery .main-slider .zoom {
    position: absolute;
    right: 25px;
    top: 22px;
    cursor: pointer;
    z-index: 9;
}
.single-product-body section.overview .container .gallery .thumbs-slider {
    width: 25%;
    height: 100%;
    margin-right: 18px;
}
.single-product-body section.overview .container .gallery .thumbs-slider .swiper-slide {
    border: 1px solid #E2E2E2;
    cursor: pointer;
}
.single-product-body section.overview .container .gallery .thumbs-slider .swiper-slide-thumb-active {
    border-color: #807CE0;
}
.single-product-body section.overview .container .overview-text {
    padding: 0 55px;
    border-left: 2px solid #E2E2E2;
}
.single-product-body section.overview .container .overview-text h6 {
    margin-bottom: 23px;
}
.single-product-body section.overview .container .overview-text p {
    font-size: 18px;
    letter-spacing: 0.54px;
    color: #7F7F7F;
}
.single-product-body section.overview .container .overview-text p strong {
    font-family: "FH-Haydon-Medium";
    color: #0D0C0C;
}
.single-product-body section.overview .container .overview-text ul {
    margin-top: 10px;
    margin-bottom: 20px;
}
.single-product-body section.overview .container .overview-text ul li {
    padding-left: 23px;
    font-size: 18px;
    line-height: 28px;
    font-family: "FH-Haydon-RegularItalic";
    color: #0D0C0C;
    letter-spacing: 0.54px;
    position: relative;
}
.single-product-body section.overview .container .overview-text ul li:before{
    margin: 0 !important;
    top: 5px !important;
}
.single-product-body section.overview .container .overview-text ul li::before {
    position: absolute;
    content: "";
    left: 0;
    width: 13px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 13px;
    border-radius: 50%;
    background-color: #807CE0;
}
.single-product-body section.overview .container .overview-text ul li:not(:last-child) {
    margin-bottom: 5px;
}
.single-product-body section.overview .container .actions {
    flex-direction: column;
}
.single-product-body section.overview .container .actions .block {
    background-color: #807ce0;
    border-radius: 10px;
    margin-bottom: 24px;
    padding: 25px 37px 48px;
    min-width:340px ;
   /* position: absolute;
    right: 0;
    */
}
.single-product-body section.overview .container .actions .block .title {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 1.92px;
    opacity: 0.85;
    padding-bottom: 13.5px;
    margin-bottom: 22.5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.55);
}
.single-product-body section.overview .container .actions .block .small-text {
    margin-bottom: 16px;
}
.single-product-body section.overview .container .actions .block button {
    border-radius: 5px;
    width: 100%;
}
.single-product-body section.overview .container .actions .block button a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.single-product-body section.overview .container .actions .block button a span {
    font-size: 16px;
    letter-spacing: 0.96px;
    line-height: 26px;
    white-space: nowrap;
}
.single-product-body section.overview .container .actions .block button.pdf {
    border: 1px solid #FFFFFF;
    margin-bottom: 49px;
}
.single-product-body section.overview .container .actions .block button.pdf a {
    padding: 13px 25px;
}
.single-product-body section.overview .container .actions .block button.pdf a img {
    max-width: 15px;
}
.single-product-body section.overview .container .actions .block button.pdf span {
    margin-left: 9px;
}
.single-product-body section.overview .container .actions .block button.add {
    background-color: #1A3173;
    position: relative;
}
.single-product-body section.overview .container .actions .block button.add a {
    padding: 18px 30px;
    z-index: 1;
    position: relative;
}
.single-product-body section.overview .container .actions .block button.add span {
    margin-right: 18px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.single-product-body section.overview .container .actions .block button.add svg {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.single-product-body section.overview .container .actions .block button.add svg path, .single-product-body section.overview .container .actions .block button.add svg line {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.single-product-body section.overview .container .actions .block button.add::before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    transform-origin: 50% 50%;
    opacity: 0;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    content: "";
    border-radius: 5px;
    background-color: #fff;
}
.single-product-body section.overview .container .actions .block button.add:hover a span {
    color: #0A1E5B;
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px);
}
.single-product-body section.overview .container .actions .block button.add:hover a svg {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
}
.single-product-body section.overview .container .actions .block button.add:hover a svg #Path_93_00, .single-product-body section.overview .container .actions .block button.add:hover a svg #Icon_metro-chevron-thin-right {
    fill: #0A1E5B;
}
.single-product-body section.overview .container .actions .block button.add:hover a svg line {
    stroke: #0A1E5B;
}
.single-product-body section.overview .container .actions .block button.add:hover::before {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
}
.single-product-body section.overview .container .actions .favorites {
    justify-content: center;
    position: relative;
    cursor: pointer;
}
.single-product-body section.overview .container .actions .favorites .add {
    align-items: center;
    background-color: #fff;
    z-index: 1;
    padding: 0 7px 0 18px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.single-product-body section.overview .container .actions .favorites .add span {
    margin-left: 11px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1.6px;
    color: #0A1E5B;
}
.single-product-body section.overview .container .actions .favorites .add svg path {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.single-product-body section.overview .container .actions .favorites::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 100%;
    height: 1px;
    background-color: #807CE0;
}
.single-product-body section.overview .container .actions .favorites .arrow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin: auto 0;
    opacity: 0;
}
.single-product-body section.overview .container .actions .favorites:hover .add {
    -webkit-transform: translateX(-40px);
    -moz-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
    -o-transform: translateX(-40px);
    transform: translateX(-40px);
}
.single-product-body section.overview .container .actions .favorites:hover .arrow {
    opacity: 1;
}
.single-product-body section.overview .container .actions .favorites.added-to-favorites .add svg path {
    fill: #0A1E5B;
}
.single-product-body section.specifications .container {
    flex-direction: column;
}
.single-product-body section.specifications .container h2 {
    margin-bottom: 55px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
.single-product-body section.specifications .container .specifications-wrapper {
    flex-wrap: wrap;
    margin-bottom: 0px;
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper {
    padding: 0 30px;
    margin-bottom: 30px;
    width: 33.3333%;
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    padding: 50px 52px;
    height: 100%;
    border-radius: 10px;
    background-color: #807CE0;
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification .illustration {
    margin-bottom: 30px;
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification h4 {
    margin-bottom: 26px;
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification p {
    font-family: "FH-Haydon-SemiBold";
    color: #fff;
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ul {
    margin-bottom: 0;
    margin-top: 10px;
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ul li {
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    padding-left: 29px;
    letter-spacing: 0.9px;
    position: relative;
    font-family: "FH-Haydon-RegularItalic";
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ul li:not(:last-child) {
    margin-bottom: 15px;
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ol {
    padding: revert;
    list-style: auto;
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ol li {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1px;
    font-family: "FH-Haydon-SemiBold";
}
.single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ol li:not(:last-child) {
    margin-bottom: 15px;
}
.single-product-body section.specifications .container .table-specifications-wrapper .single-table-specification {
    width: 100%;
}
.single-product-body section.specifications .container .cta {
    margin-top: 20px;
    padding-top: 48px;
    border-top: 1px solid rgba(255, 255, 255, 0.34);
}
.single-product-body section.specifications .container .cta .left {
    max-width: 580px;
    padding-right: 20px;
}
.single-product-body section.specifications .container .cta .left h6 {
    margin-bottom: 15px;
}
.single-product-body section.specifications .container .cta .left p {
    font-family: "FH-Haydon-RegularItalic";
    color: #fff;
}
.single-product-body section.specifications.has-table {
    background-color: #EFEFEF;
}
.single-product-body section.specifications.has-table .container h2 {
    color: #0A1E5B;
    margin-bottom: 30px;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper {
    flex-direction: column;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper > div:not(:last-child) {
    margin-bottom: 40px;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper > div h3 {
    margin-bottom: 20px;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper > div h5 {
    color: #807CE0;
    margin-bottom: 30px;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper > div .table-container {
    margin-top: 20px;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper > div .single-table-specification__text {
    margin-top: 50px;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper > div .single-table-specification__text ul li {
    font-size: 18px;
    line-height: 24px;
    padding-left: 30px;
    letter-spacing: 1px;
    color: #0A1E5B;
    position: relative;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper > div .single-table-specification__text ul li:not(:last-child) {
    margin-bottom: 10px;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper > div .single-table-specification__text ul li::before {
    position: absolute;
    content: "";
    top: 3px;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #DBDBDB;
}
.single-product-body section.specifications.has-table .container .table-specifications-wrapper > div .single-table-specification-category:not(:last-child) {
    margin-bottom: 50px;
}
.single-product-body section.specifications.has-table .container .cta {
    color: #0A1E5B;
    border-color: #807ce042;
}
.single-product-body section.specifications.has-table .container .cta .left p {
    color: #0A1E5B;
}
.single-product-body section.specifications.has-table .container .cta button a svg #Icon_metro-chevron-thin-right, .single-product-body section.specifications.has-table .container .cta button a svg #Path_93_000 {
    fill: #0A1E5B;
}
.single-product-body section.specifications.has-table .container .cta button a svg #Line_22 {
    stroke: #0A1E5B;
}
.single-product-body section.technical-info .container {
    flex-direction: column;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
.single-product-body section.technical-info .container h2 {
    margin-bottom: 22px;
}
.single-product-body section.technical-info .container h3, .single-product-body section.technical-info .container h4, .single-product-body section.technical-info .container h5, .single-product-body section.technical-info .container h6 {
    margin: 15px 0 10px;
}
.single-product-body section.technical-info .container > .text {
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    letter-spacing: 1px;
}
.single-product-body section.technical-info .container >.title {
    font-size: 22px;
    line-height: 32px;
}
.single-product-body section.technical-info .container > .text.text-regular {
    margin-bottom: 7px;
    opacity: 0.61;
}
.single-product-body section.technical-info .container > .text.text-strong {
    font-family: "FH-Haydon-MediumItalic";
}
.single-product-body section.technical-info .container .table-container {
    margin-bottom: 30px;
}
.single-product-body section.technical-info .container .table-container .bubble {
    position: absolute;
    background-color: #0A1E5B;
    color: white;
    top: -20px;
    z-index: 99;
    padding: 5px 10px;
    font-family: "FH-Haydon-Medium";
    border-radius: 5px;
    font-size: 14px;
    animation: bubbleUp 1.5s;
}
.single-product-body section.technical-info .container .table-container table {
    margin-top: 30px;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child .add-to-favorites {
    line-height: normal;
    margin-right: 20px;
    cursor: pointer;
    position: relative;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child .add-to-favorites svg path {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child .add-to-favorites.added-to-favorites svg path {
    fill: #0A1E5B;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button {
    background-color: #0A1E5B;
    position: relative;
    border-radius: 5px;
    margin-right: 21px;
    min-width: 127px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border: 1px solid transparent;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    padding: 5px 13px;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button a svg {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
    opacity: 0;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button a svg path {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button a span {
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.26px;
    font-family: "FH-Haydon-SemiBold";
    color: #FFFFFF;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    white-space: nowrap;
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px);
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button:hover svg {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button:hover span {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button.added-to-project {
    border-color: #0A1E5B;
    background-color: transparent;
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button.added-to-project span {
    color: #0A1E5B;
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button.added-to-project svg {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}
.single-product-body section.technical-info .container .table-container table tbody > tr td:first-child button.added-to-project svg path {
    fill: #0A1E5B;
}
.single-product-body section.technical-info .container .cta {
    padding-bottom: 50px;
    border-bottom: 1px solid #DBDBDB;
}
.single-product-body section.technical-info .container .cta .left {
    align-items: center;
}
.single-product-body section.technical-info .container .cta .left img {
    width: 20px;
}
.single-product-body section.technical-info .container .cta .left span {
    margin-left: 13px;
    color: #807CE0;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1px;
    opacity: 0.67;
}
.single-product-body section.technical-info .container .notes {
    margin-top: 35px;
}
.single-product-body section.technical-info .container .notes .title {
    align-items: center;
    margin-bottom: 21px;
}
.single-product-body section.technical-info .container .notes .title h5 {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
    font-family: "FH-Haydon-SemiBold";
}
.single-product-body section.technical-info .container .single-table-notes__text {
    padding-left: 50px;
    max-width: 1380px;
}
.single-product-body section.technical-info .container .single-table-notes__text:not(:last-child) {
    margin-bottom:45px;
}
.single-product-body section.technical-info .container .single-table-notes__text ul li {
    font-size: 20px;
    line-height: 24px;
    font-family: "FH-Haydon-RegularItalic";
    padding-left: 40px;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.68);
    position: relative;
}
.single-product-body section.technical-info .container .single-table-notes__text ul li:not(:last-child) {
    margin-bottom: 15px;
}
.single-product-body section.technical-info .container .single-table-notes__text ul li::before {
    position: absolute;
    content: "";
    top: 3px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #DBDBDB;
}
.single-product-body section.technical-info .container .single-table-notes__text ol li{
    padding-left: 15px;
    letter-spacing: 1px;
    color: #000000;
    position: relative;
    font-size: 20px;
    line-height: 24px;
}
.single-product-body section.technical-info .container .single-table-notes__text ol li:not(:last-child){
    margin-bottom: 10px;
}
.single-product-body section.technical-info .container .single-table-notes__text ol{
    list-style: revert;
    padding: revert;
    margin: 10px 0;
}
.single-product-body section.technical-info .container ul, .single-product-body section.specifications.has-table .container ul {
    margin: 10px 0;
}
.single-product-body section.technical-info .container ul li, .single-product-body section.specifications.has-table .container ul li {
    font-size: 18px;
    line-height: 24px;
    padding-left: 30px;
    letter-spacing: 1px;
    color: #0A1E5B;
    position: relative;
}
.single-product-body section.technical-info .container ul li::before, .single-product-body section.specifications.has-table .container ul li::before {
    position: absolute;
    content: "";
    top: 3px;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #DBDBDB;
}
.single-product-body section.technical-info .container ul li:not(:last-child), .single-product-body section.specifications.has-table .container ul li:not(:last-child) {
    margin-bottom: 10px;
}
.single-product-body .table-container {
    overflow-x: auto;
}
.single-product-body .table-container table {
    border-radius: 10px;
    overflow: hidden;
}
.single-product-body .table-container table thead > tr th {
    padding: 12px;
    background-color: #0A1E5B;
    color: #fff;
    font-family: "FH-Haydon-Medium";
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 1.44px;
    border: 1px solid #fff;
}
.single-product-body .table-container table tbody > tr:nth-child(odd) {
    background-color: #F0F3FF;
}
.single-product-body .table-container table tbody > tr:nth-child(even) {
    background-color: #E5E4F8;
}
.single-product-body .table-container table tbody > tr td {
    color: #000;
    padding: 15px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.8px;
    font-family: "Helvetica-Regular";
}
.single-product-body .table-container table, .single-product-body .table-container th, .single-product-body .table-container td {
    border-collapse: collapse;
    text-align: center;
    vertical-align: middle;
}
.news-body .container {
    padding-top: 200px;
}
.news-body .container .sidebar-wrapper {
    width: 26%;
    padding-right: 35px;
    position: relative;
}
.news-body .container .sidebar-wrapper .sidebar {
    border-radius: 10px;
    background-color: #0A1E5B;
    padding: 46px 34px;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
.news-body .container .sidebar-wrapper .sidebar form {
    position: relative;
    margin-bottom: 28px;
}
.news-body .container .sidebar-wrapper .sidebar form input {
    width: 100%;
    background-color: #FFFFFF;
    outline: none;
    border-radius: 5px;
    font-size: 16px;
    line-height: 29px;
    color: #0A1E5B;
    padding: 18px 50px 18px 24px;
    letter-spacing: 0.8px;
}
.news-body .container .sidebar-wrapper .sidebar form input::placeholder {
    opacity: 0.66;
}
.news-body .container .sidebar-wrapper .sidebar form button.submit, .news-body .container .sidebar-wrapper .sidebar form button.clear {
    position: absolute;
    right: 26px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}
.news-body .container .sidebar-wrapper .sidebar form button.clear svg {
    width: 19x;
    height: 19px;
}
.news-body .container .sidebar-wrapper .sidebar form button.clear svg path {
    stroke: #0A1E5B;
}
.news-body .container .sidebar-wrapper .sidebar form button.submit img {
    max-width: 20px;
}
.news-body .container .sidebar-wrapper .sidebar .title {
    font-size: 24px;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 15px;
    letter-spacing: 1.92px;
}
.news-body .container .sidebar-wrapper .sidebar .tags {
    margin-bottom: 25px;
}
.news-body .container .sidebar-wrapper .sidebar .tags .tags-wrapper {
    flex-wrap: wrap;
}
.news-body .container .sidebar-wrapper .sidebar .tags .tags-wrapper .single-tag {
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 8px 13px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.12);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.12px;
    font-family: "FH-Haydon-LightItalic";
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter {
    width: 100%;
    margin-bottom: 15px;
}
.news-body .container .sidebar-wrapper .sidebar .clear-filters {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    color: #fff;
    cursor: pointer;
    background-color: #807CE0;
    align-items: center;
    padding: 5px 8px;
    width: fit-content;
    border-radius: 5px;
    font-size: 16px;
    line-height: 26px;
    font-family: "FH-Haydon-LightItalic";
    letter-spacing: 1.28px;
}
.news-body .container .sidebar-wrapper .sidebar .clear-filters svg {
    margin-right: 4px;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .title {
    font-family: "FH-Haydon-Light";
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters {
    flex-direction: column;
    width: 100%;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .year-wrapper {
    margin-bottom: 24px;
    padding: 14px 18px;
    width: 100%;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    background-color: #28396F;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .year-wrapper .prev, .news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .year-wrapper .next {
    cursor: pointer;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .year-wrapper .prev img, .news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .year-wrapper .next img {
    vertical-align: middle;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .year-wrapper .year {
    font-size: 16px;
    line-height: 26px;
    align-items: center;
    letter-spacing: 1.28px;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .year-wrapper .year span {
    margin-right: 11px;
    opacity: 0.7;
    text-transform: uppercase;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .year-wrapper .year #current {
    font-family: "FH-Haydon-Medium";
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .months {
    width: 100%;
    flex-direction: column;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .months .month {
    align-items: center;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
    padding-bottom: 15px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 1.28px;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .months .month:not(:last-child) {
    margin-bottom: 11px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.44);
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .months .month span {
    opacity: 0.7;
}
.news-body .container .sidebar-wrapper .sidebar .archive-filter .filters .months .month.active span {
    opacity: 1;
}
.news-body .container .main-content {
    width: 74%;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    padding-left: 40px;
    flex-direction: column;
}
.news-body .container .main-content h1 {
    margin-bottom: 24px;
}
.news-body .container .main-content p {
    font-size: 18px;
    line-height: 24px;
    color: #0A1E5B;
    letter-spacing: 0.9px;
    max-width: 825px;
}
.news-body .container .main-content .news-wrapper {
    flex-wrap: wrap;
    margin-top: 45px;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper {
    padding: 0 20px;
    margin-bottom: 40px;
    width: 33.3333%;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a .date {
    position: absolute;
    z-index: 9;
    letter-spacing: 1.28px;
    left: 10px;
    font-size: 15px;
    line-height: 25px;
    top: unset;
    bottom: 10px;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a .category {
    right: 10px;
    font-size: 16px;
    line-height: 26px;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 10px;
    background-color: #0A1E5B;
    opacity: 0.28;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news h6 {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 2px;
    margin-bottom: 10px;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .read-more {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .read-more span {
    margin-right: 10px;
    font-size: 14px;
    line-height: 19px;
    color: #0A1E5B;
    letter-spacing: 1.12px;
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news:hover .image img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news:hover .image::before {
    opacity: 0.05;
}
.news-single-body .container {
    padding-top: 150px;
    padding-bottom: 0;
    flex-direction: column;
}
.news-single-body .container .text-content img {
    max-width:100%;
    margin:20px 0;
}
.news-single-body .container .banner {
    margin-bottom: 57px;
    width: 100%;
    padding: 61px 74px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
    border-radius: 10px;
    overflow: hidden;
    min-height: 700px;
    align-items: flex-end;
    position: relative;
}
.news-single-body .container .banner .bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.news-single-body .container .banner .bg-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.news-single-body .container .banner .text {
    position: relative;
    z-index: 2;
}
.news-single-body .container .banner .text .date {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.9px;
    margin-bottom: 15.5px;
}
.news-single-body .container .banner .text .divider {
    margin-bottom: 24px;
    width: 46px;
    height: 1px;
    background-color: #fff;
}
.news-single-body .container .banner .text h2 {
    max-width: 965px;
}
.news-single-body .container .banner::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    left: 0;
    top: 0;
    z-index: 1;
    background: -moz-linear-gradient(180deg, rgba(3, 15, 52, 0) 0%, #031345 100%);
    background: -webkit-linear-gradient(180deg, rgba(3, 15, 52, 0) 0%, #031345 100%);
    background: linear-gradient(180deg, rgba(3, 15, 52, 0) 0%, #031345 100%);
    opacity: 0.81;
}
.news-single-body .container .banner .category {
    position: absolute;
    top: 45px;
    right: 45px;
    z-index: 2;
    padding: 6px 14px;
    font-size: 18px;
    line-height: 26px;
    text-transform: capitalize;
    border-radius: 5px;
    background-color: rgba(10, 30, 91, 0.49);
    letter-spacing: 0.9px;
}
.news-single-body .container .text-content {
    margin-bottom: 130px;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
}
.news-single-body .container .text-content p {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 1.2px;
}
.news-single-body.news-video .container {
    padding-top: 200px;
}
.news-single-body.news-video .container h2 {
    max-width: 965px;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
.news-single-body.news-video .container .video-banner {
    width: 100%;
    margin-bottom: 57px;
    margin-top: 30px;
    border-radius: 10px;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
.news-single-body.news-video .container .video-banner video {
    width: 100%;
    border-radius: 10px;
    height: auto;
}
.news-single-slider .container {
    padding-top: 0;
}
.news-single-slider .container h2 {
    margin-bottom: 56px;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
.news-single-slider .container .swiper {
    opacity: 0;
}
.cross-reference-body {
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 100px;
}
.cross-reference-body .search-sticky-container .search-block {
    flex-direction: column;
}
.cross-reference-body .search-sticky-container .search-block h3 {
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
.cross-reference-body .search-sticky-container.is-fixed h3 {
    width: 87.5%;
    max-width: 1680px;
    font-size: 30px;
    line-height: 40px;
}
.cross-reference-body .container {
    flex-direction: column;
}
.cross-reference-body .container .search-tips-block {
    width: 100%;
    padding: 41px 41px 47px 62px;
    margin-bottom: 100px;
    background-color: #FFFFFF;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
    border-radius: 10px;
    flex-direction: column;
}
.cross-reference-body .container .search-tips-block.opened .head .circle .plus-minus > div:last-child {
    -webkit-transform: translateX(-50%) rotate(180deg);
    -moz-transform: translateX(-50%) rotate(180deg);
    -ms-transform: translateX(-50%) rotate(180deg);
    -o-transform: translateX(-50%) rotate(180deg);
    transform: translateX(-50%) rotate(180deg);
}
.cross-reference-body .container .search-tips-block .head {
    width: 100%;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}
.cross-reference-body .container .search-tips-block .head .left {
    align-items: center;
}
.cross-reference-body .container .search-tips-block .head .left h3 {
    margin-left: 18px;
    color: #807CE0;
}
.cross-reference-body .container .search-tips-block .head .circle {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #807CE0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.cross-reference-body .container .search-tips-block .head .circle .plus-minus {
    width: 23px;
    height: 23px;
    position: relative;
}
.cross-reference-body .container .search-tips-block .head .circle .plus-minus > div {
    position: absolute;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #FFFFFF;
}
.cross-reference-body .container .search-tips-block .head .circle .plus-minus > div:first-child {
    width: 23px;
    height: 2px;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.cross-reference-body .container .search-tips-block .head .circle .plus-minus > div:last-child {
    left: 50%;
    top: 0;
    width: 2px;
    height: 23px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.cross-reference-body .container .search-tips-block .content {
    margin-top: 15px;
    margin-left: 50px;
}
.cross-reference-body .container .search-tips-block .content p {
    font-size: 16px;
    line-height: 26px;
    color: #808080;
    letter-spacing: 0.8px;
}
.cross-reference-body .container .search-tips-block .content .bottom {
    width: 100%;
    margin-top: 32px;
    padding-top: 40px;
    border-top: 1px solid #E3E3E3;
}
.cross-reference-body .container .search-tips-block .content .bottom ul li {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.9px;
    padding-left: 32px;
    position: relative;
    color: #807CE0;
}
.cross-reference-body .container .search-tips-block .content .bottom ul li:not(:last-child) {
    margin-bottom: 15px;
}
.cross-reference-body .container .search-tips-block .content .bottom ul li::before {
    position: absolute;
    content: "";
    left: 0;
    top: 3px;
    width: 18px;
    height: 18px;
    background-image: url('../../../src/assets/icons/info-circle-no-bg.svg');
    background-repeat: no-repeat;
}
.cross-reference-body .container .search-results-blocks-wrapper {
    flex-direction: column;
}
.cross-reference-body .container .search-results-blocks-wrapper .results-found {
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 1.4px;
    color: #0A1E5B;
    margin-bottom: 42px;
    font-family: "FH-Haydon-Medium";
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks {
    flex-direction: column;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result {
    width: 100%;
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px);
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 42px 75px 42px 38px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result:not(:last-child) {
    margin-bottom: 43px;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .image {
    width: 324px;
    height: auto;
    margin-right: 75px;
    min-width: 324px;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .image img {
    max-width: 100%;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content {
    flex-direction: column;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content h3 {
    margin-bottom: 36px;
    font-family: "FH-Haydon-SemiBold";
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta {
    align-items: center;
    width: 100%;
    margin-top: 40px;
    justify-content: flex-end;
    padding-top: 41px;
    border-top: 1px solid #D3D3D3;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .add-to-favorites {
    margin-right: 30px;
    cursor: pointer;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .add-to-favorites svg {
    width: 30px;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .add-to-favorites svg path {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .add-to-favorites.added-to-favorites svg path {
    fill: #807CE0;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn {
    margin-right: 32px;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn a {
    padding: 15px 45px;
    border-color: #807CE0;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn a span {
    color: #807CE0;
    margin-right: 18px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.96px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn a svg {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn a svg path, .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn a svg line {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn:hover a span {
    color: #FFFFFF;
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    -o-transform: translateX(-5px);
    transform: translateX(-5px);
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn:hover a svg {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px);
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn:hover a svg #Icon_metro-chevron-thin-right, .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn:hover a svg #Path_93_00 {
    fill: #fff;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn:hover a svg line {
    stroke: #FFFFFF;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .view-product {
    border-radius: 5px;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .view-product a {
    border-radius: 5px;
    padding: 15px 35px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    background-color: #807CE0;
    align-items: center;
    justify-content: center;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .view-product a span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.96px;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-footer {
    justify-content: space-between;
    margin-top: 64px;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-footer .left {
    padding-right: 20px;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.9px;
    color: #0A1E5B;
    opacity: 0.63;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-footer .pagination-wrapper {
    padding-left: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-footer .pagination-wrapper .pagination-block {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 0 4px;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 1.1px;
    font-family: "FH-Haydon-Medium";
    color: #0A1E5B;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #fff;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-footer .pagination-wrapper .pagination-block.current {
    background-color: #807CE0;
    color: #fff;
}
.cross-reference-body .container .search-results-blocks-wrapper .search-results-footer .pagination-wrapper .pagination-block.disabled {
    opacity: 0.64;
    cursor: not-allowed;
}
section.legal-body .container {
    flex-direction: column;
    padding-top: 180px;
}
section.legal-body .container h2 {
    margin-bottom: 30px;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
section.legal-body .container .text-content {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
section.legal-body .container .text-content h1, section.legal-body .container .text-content h2, section.legal-body .container .text-content h3, section.legal-body .container .text-content h4, section.legal-body .container .text-content h5, section.legal-body .container .text-content h6 {
    color: #0A1E5B;
}
.not-found-body .container {
    flex-direction: column;
    align-items: center;
}
.not-found-body .container h2 {
    font-family: "FH-Haydon-Regular";
}
.not-found-body .container button {
    margin-top: 30px;
}
.not-found-body .container button a {
    padding: 18px 30px;
    border-color: #0A1E5B;
}
.not-found-body .container button a span {
    color: #0A1E5B;
    margin-left: 15px;
    font-size: 16px;
    line-height: 24px;
    font-family: "FH-Haydon-Medium";
    letter-spacing: 0.96px;
}
.not-found-body .container button a img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.not-found-body .container button::before {
    background-color: #f2f2f2;
}
.not-found-body .container button:hover a img {
    -webkit-transform: translateX(-7px);
    -moz-transform: translateX(-7px);
    -ms-transform: translateX(-7px);
    -o-transform: translateX(-7px);
    transform: translateX(-7px);
}
@media (max-width: 1680px) {
    section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member:hover .information {
        max-width: 185px;
   }
}
@media (max-width: 1599px) {
    .footer .container .footer-content .col button {
        min-width: 160px;
   }
    .footer .container .footer-content .col button a {
        padding: 15px 35px;
   }
    .footer .container .certifications .right > .logo {
        padding: 0 12px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li:not(:last-child) {
        margin-bottom: 10px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content, .header .mega-menu.lg .container .mega-menu-wrapper .image {
        padding: 35px 30px;
   }
    .search-block .search-container .search-results {
        max-height: 350px;
   }
    h1, section.entry-banner .container .text-content h1 {
        font-size: 75px;
        line-height: 85px;
        letter-spacing: 4px;
   }
    h2 {
        font-size: 50px;
        line-height: 60px;
   }
    section.entry-banner.hp .swiper .swiper-slide .text-content {
        max-width: 940px;
   }
    section.products .container > h1 {
        font-size: 70px;
        line-height: 80px;
        margin-bottom: 50px;
   }
    section.products .container > .categories-wrapper .single-category-wrapper {
        padding: 0 10px;
   }
    section.resources .container .resources-wrapper {
        margin-left: -20px;
        margin-right: -20px;
   }
    section.resources .container .resources-wrapper .single-resource-wrapper {
        padding: 0 20px;
        margin-bottom: 40px;
   }
    section.about-section .container > div.left-column {
        padding-right: 30px;
   }
    section.about-section .container > div.left-column .image-block:first-child .image {
        height: 650px;
   }
    section.about-section .container > div.left-column .image-block:nth-child(2) {
        width: 90%;
   }
    section.about-section .container > div.left-column .image-block:nth-child(2) .image {
        height: 600px;
   }
    section.about-section .container > div.right-column {
        padding-left: 30px;
   }
    section.about-section .container > div.right-column .image-block .image {
        height: 550px;
   }
    section.about-body-section .container > div.upper .image {
        height: 700px;
   }
    section.about-body-section .container > div.bottom .image {
        height: 600px;
   }
    section.team .container .team-wrapper .team-members {
        margin-left: 0;
        margin-right: 0;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper {
        padding: 0 15px;
        margin-bottom: 50px;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image {
        height: 500px;
   }
    section.certifications-section .container .certifications-wrapper {
        margin-left: 0;
        margin-right: 0;
   }
    section.certifications-section .container .certifications-wrapper .single-certification-wrapper {
        padding: 0 10px;
        margin-bottom: 20px;
   }
    .catalog-body .container .sidebar {
        padding: 200px 30px 200px 0;
   }
    .catalog-body::before {
        width: 34%;
   }
    .catalog-body .container .sidebar .action .btn-wrapper button:first-child {
        margin-right: 15px;
   }
    .catalog-body .container .sidebar .action .btn-wrapper button a {
        padding: 15px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper {
        padding: 0 8px;
        margin-bottom: 16px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .product-image {
        height: 115px;
   }
    section.contact-body .container .main-content .left .blocks .block {
        min-width: 100%;
   }
    section.contact-body .container .map {
        height: 600px;
   }
    section.contact-details .container .single-detail-wrapper {
        padding: 0 10px;
        margin-bottom: 20px;
   }
    section.contact-details .container .contact-info .single-detail-wrapper .single-detail, section.contact-details .container .locations .single-detail-wrapper .single-detail a {
        padding: 60px 30px;
   }
    .cross-reference-body .search-sticky-container.is-fixed h3 {
        margin-bottom: 10px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data {
        padding: 0 10px;
        margin-bottom: 20px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data {
        width: 25%;
   }
    .navigation-sticky-container.is-sticky .navigation-wrapper {
        padding-top: 20px !important;
   }
    .news-body .container .sidebar-wrapper {
        padding-right: 20px;
   }
    .news-body .container .main-content {
        padding-left: 20px;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper{
        padding: 0 15px;
        margin-bottom: 30px;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper .single-news h6{
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 1.4px;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a {
        height: 350px;
   }
    .news-single-body .container .banner {
        min-height: 550px;
   }
    section.news-slider.news-single-slider .container .swiper .swiper-button-next, section.news-slider.news-single-slider .container .swiper .swiper-button-prev {
        top: -75px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(odd) .text-block {
        padding-right: 30px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(odd) .image-block {
        padding-left: 30px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(even) .text-block {
        padding-left: 30px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(even) .image-block {
        padding-right: 30px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper:not(:last-child) {
        margin-bottom: 100px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper {
        padding: 0 15px;
   }
    .single-product-body .table-container table thead > tr th {
        font-size: 15px;
        line-height: 22px;
   }
    .single-product-body section.technical-info .container .table-container table tbody > tr td:first-child {
        padding-left: 15px;
   }
    .single-product-body .table-container table tbody > tr td {
        padding: 8px;
   }
}
@media (max-width: 1399px) {
    section.contact-details .container .contact-info .single-detail-wrapper .single-detail, section.contact-details .container .locations .single-detail-wrapper .single-detail a {
        padding: 50px 20px;
   }
    section.contact-details .container .single-detail-wrapper .single-detail p {
        font-size: 23px;
        line-height: 33px;
   }
    .header .main-header .container .logo {
        max-width: 165px;
   }
    .header .main-header .container .right ul li:not(:last-child) {
        margin-right: 20px;
   }
    .header.banner-header .main-header .container {
        padding-left: 17px;
        padding-right: 17px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .image {
        padding: 30px 25px !important;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content {
        padding: 40px 35px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content .col {
        padding: 0 20px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content .col .title {
        margin-bottom: 15px;
        padding-bottom: 10px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li:not(:last-child) {
        margin-bottom: 8px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li:hover a {
        -webkit-transform: translateX(3px);
        -moz-transform: translateX(3px);
        -ms-transform: translateX(3px);
        -o-transform: translateX(3px);
        transform: translateX(3px);
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li:hover::after {
        right: -6px;
   }
    .footer .container .certifications .right {
        width: 65%;
   }
    .footer .container .certifications .right > .logo{
        padding: 0 10px;
   }
    .footer .container .footer-content .col button {
        min-width: 145px;
   }
    .footer .container .footer-content .col button a {
        padding: 15px 20px;
   }
    h1, section.entry-banner .container .text-content h1 {
        font-size: 70px;
        line-height: 80px;
   }
    h2 {
        font-size: 45px;
        line-height: 55px;
   }
    section.products .container > .categories-wrapper .single-category-wrapper .category {
        min-height: 500px;
   }
    section.products .container > .categories-wrapper .single-category-wrapper .category .title {
        padding: 23px 20px;
   }
    section.products .container > .categories-wrapper .single-category-wrapper .category .bottom {
        padding: 0 20px 23px 20px;
   }
    section.resources .container .resources-wrapper {
        margin-left: -15px;
        margin-right: -15px;
   }
    section.resources .container .resources-wrapper .single-resource-wrapper {
        padding: 0 15px;
        margin-bottom: 30px;
   }
    section.resources .container .resources-wrapper .single-resource-wrapper .resource > a {
        padding: 30px 40px 27px;
   }
    section.about-section .container > div.left-column .image-block:first-child .image {
        height: 600px;
   }
    section.about-section .container > div.left-column .image-block:nth-child(2) {
        width: 90%;
   }
    section.about-section .container > div.left-column .image-block:nth-child(2) .image {
        height: 550px;
   }
    section.about-section .container > div.right-column .image-block .image {
        height: 500px;
   }
    section.about-section .container > div.right-column .text-block {
        margin-bottom: 150px;
   }
    section.about-body-section .container > div.upper .image {
        height: 600px;
        padding-right: 20px;
   }
    section.about-body-section .container > div.upper .text-block {
        padding-left: 20px;
   }
    section.about-body-section .container > div.bottom .text-block {
        padding-right: 20px;
   }
    section.about-body-section .container > div.bottom .image {
        height: 500px;
        padding-left: 20px;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member:hover .information {
        max-width: 100%;
        -webkit-transform: translateY(0) translateX(0);
        -moz-transform: translateY(0) translateX(0);
        -ms-transform: translateY(0) translateX(0);
        -o-transform: translateY(0) translateX(0);
        transform: translateY(0) translateX(0);
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image {
        height: 450px;
   }
    section.certifications-section .container .certifications-wrapper .single-certification-wrapper {
        width: 50%;
   }
    .catalog-body .container .sidebar .action .btn-wrapper {
        flex-direction: column;
   }
    .catalog-body .container .sidebar .action .btn-wrapper button {
        width: fit-content;
   }
    .catalog-body .container .sidebar .action .btn-wrapper button:first-child {
        margin-right: 0;
        margin-bottom: 15px;
   }
    .catalog-body .container .main-content {
        padding: 200px 0 200px 40px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper {
        width: 33.3333%;
   }
    section.contact-body .container .main-content .left {
        padding-right: 25px;
   }
    section.contact-body .container .main-content .right {
        padding-left: 25px;
   }
    section.contact-body .container .main-content .right .form-wrapper {
        padding: 50px;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__question .question {
        font-size: 30px;
        line-height: 38px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data, section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data {
        width: 33.33%;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data {
        min-height: 400px;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper {
        width: 50%;
   }
    .news-body .container .sidebar-wrapper {
        padding-right: 0;
   }
    .news-body .container .main-content {
        padding-left: 40px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper:not(:last-child) {
        margin-bottom: 70px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block {
        padding-left: 0 !important;
        padding-right: 0 !important;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(even) .image-block {
        padding-right: 50px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper:nth-child(odd) .image-block {
        padding-left: 50px;
   }
    .single-product-body section.overview .container .overview-text {
        padding: 0 30px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper {
        padding: 0 10px;
        margin-bottom: 20px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification {
        padding: 30px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification .illustration {
        max-width: 60px;
        margin-bottom: 20px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification .illustration img {
        max-width: 100%;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification h4 {
        margin-bottom: 15px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification p {
        font-size: 18px;
        line-height: 26px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ol li {
        font-size: 18px;
        line-height: 24px;
   }
}
@media (max-width: 1200px){
    .catalog-body .container .main-content .selected-filters .view-more .view-more-body{
        display: none;
    }
      .catalog-body .container .main-content .selected-filters .view-more .view-more-btn{
        display: block;
    }
}
@media (max-width: 1199px) {
    .single-product-body section.overview .container .actions .block {
        min-width: 250px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification {
        padding: 30px 25px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification h4 {
        margin-bottom: 10px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification p, .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ol li {
        font-family: "FH-Haydon-Regular";
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content .col .title {
        margin-bottom: 13px;
        padding-bottom: 10px;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 1px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .image .text h2 {
        margin-bottom: 5px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .image .text button {
        margin-top: 15px;
   }
    .container, .navigation-sticky-container .navigation-wrapper .navigation, section.faqs-body .cta, section.faqs-body .faqs-wrapper, .search-block.fixed, .cross-reference-body .search-sticky-container.is-fixed h3, .search-sticky-container.is-fixed .search-block .search-container {
        width: 95%;
   }
    .header .main-header .container .right .cart .cart-tooltip {
        left: -110px;
   }
    .header .main-header .container .right .cart .cart-tooltip::after {
        left: unset;
        right: 34px;
   }
    .header .mega-menu.sm {
        left: -55px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content {
        padding: 30px 20px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .content .col ul li a {
        font-size: 15px;
        line-height: 24px;
   }
    .header .mega-menu.lg .container .mega-menu-wrapper .image .text button a {
        font-size: 15px;
   }
    .header .main-header .container .logo {
        max-width: 135px;
   }
    .header .main-header .container .right .divider {
        margin: 0 15px;
   }
    .header .main-header .container .right ul li:not(:last-child) {
        margin-right: 15px;
        font-size: 15px;
   }
    .header .mega-menu .container .mega-menu-wrapper .image {
        padding: 30px 20px !important;
   }
    h1, section.entry-banner .container .text-content h1, section.products .container > h1 {
        font-size: 65px;
        line-height: 75px;
   }
    h2 {
        font-size: 40px;
        line-height: 50px;
   }
    .footer .container .footer-content > div:nth-child(2) {
        padding-right: 20px;
   }
    .footer .container .footer-content .col {
        padding: 0 10px;
   }
    .footer .container .footer-content .col button {
        min-width: 138px;
   }
    .footer .container .certifications .right > .logo {
        padding: 0 15px;
   }
    section.about-section .container > div.left-column {
        padding-right: 20px;
   }
    section.about-section .container > div.left-column .image-block:first-child .image {
        height: 700px;
   }
    section.about-section .container > div.left-column .image-block:nth-child(2) .image {
        height: 600px;
   }
    section.about-section .container > div.right-column {
        padding-left: 20px;
   }
    section.about-section .container > div.right-column .image-block .image {
        height: 500px;
   }
    section.entry-banner.hp .scroll {
        right: 0;
   }
    section.products .container .search-block {
        margin-bottom: 70px;
   }
    section.products .container > .categories-wrapper .single-category-wrapper {
        width: 50%;
   }
    section.resources .container .resources-wrapper .single-resource-wrapper {
        width: 50%;
   }
    section.about-section .container > div.left-column .image-block:first-child .image {
        height: 550px;
   }
    section.about-section .container > div.left-column .image-block:nth-child(2) .image {
        height: 500px;
   }
    section.about-section .container > div.right-column .image-block .image {
        height: 450px;
   }
    section.about-section .container > div.right-column .text-block {
        margin-bottom: 100px;
   }
    section.about-body-section .container > div > div .block {
        margin-bottom: 20px;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper {
        width: 50%;
   }
    section.contact-body .container .main-content .left .blocks .block a {
        padding: 30px;
   }
    section.contact-body .container .main-content .left .blocks .block a img:not(.arrow) {
        margin-right: 20px;
        max-width: 36px;
        max-height: 48px;
   }
    section.contact-body .container .main-content .left .blocks .block a span {
        font-size: 24px;
        line-height: 34px;
   }
    section.contact-body .container .main-content .left .blocks .block a svg:first-child {
        margin-right: 20px;
        max-width: 36px;
        max-height: 48px;
   }
    section.contact-body .container .main-content .left p, section.contact-details .container .single-detail-wrapper .single-detail p {
        font-size: 22px;
        line-height: 32px;
   }
    section.contact-body .container .main-content .right .form-wrapper form .input-block:not(.full) {
        width: 48%;
   }
    section.contact-body .container .main-content .right .form-wrapper form .input-block {
        margin-bottom: 30px;
   }
    section.contact-body .container .map {
        height: 400px;
   }
    section.contact-details .container .contact-info .single-detail-wrapper .single-detail, section.contact-details .container .locations .single-detail-wrapper .single-detail a {
        padding: 45px 22px;
   }
    .cross-reference-body .container .search-tips-block {
        padding: 30px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .image {
        width: 250px;
        height: auto;
        margin-right: 75px;
        min-width: 250px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content h3 {
        margin-bottom: 20px;
   }
    section.faqs-body .faqs-wrapper .faq-item {
        padding: 30px 40px;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__answer {
        margin-top: 23.7px;
        padding-top: 28.5px;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer button a {
        white-space: nowrap;
   }
    .news-body .container {
        flex-direction: column;
   }
    .news-body .container > div {
        padding: 0 !important;
   }
    .news-body .container > div.sidebar-wrapper {
        width: 70%;
        margin-bottom: 80px;
   }
    .news-body .container > div.main-content {
        width: 100%;
   }
    .news-single-body .container .banner {
        padding: 40px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper:not(:last-child) {
        margin-bottom: 50px;
   }
    .catalog-body .container .main-content .catalog-category-custom-margin .main-text {
        font-size: 18px;
        line-height: 26px;
   }
    .catalog-body .container .main-content .catalog-category-custom-margin .category-level-two .cl2-text {
        font-size: 18px;
        line-height: 28px;
   }
}
@media (max-width: 991px) {
    .header .main-header .container .right .cart .cart-count {
        width: 26px;
        height: 26px;
        right: -15px;
   }
    .not-found-body .container .illustration {
        max-width: 400px;
   }
    .not-found-body .container .illustration img {
        max-width: 100%;
   }
    .search-block {
        padding: 40px;
   }
    .search-sticky-container, .navigation-sticky-container {
        top: 105px;
   }
    .navigation-sticky-container .navigation-wrapper .navigation .single-navigation:not(:last-child) {
        margin-right: 50px;
   }
    .header .main-header .container .right .cart .cart-tooltip {
        left: -120px;
   }
    .header .main-header .container .right .cart .cart-tooltip::after {
        left: unset;
        right: 24px;
   }
    .header .main-header .container .right > ul, .header .main-header .container .right .divider {
        display: none;
   }
    .header .main-header .container .mobile-menu-button {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
   }
    .header .mega-menu {
        display: none;
   }
    .search-block .search-container .search-results {
        padding: 10px 30px 30px 30px;
   }
    .header.banner-header .main-header .container, .header.header-fixed .main-header .container {
        padding-top: 15px;
        padding-bottom: 15px;
   }
    h1, section.entry-banner .container .text-content h1, section.products .container > h1 {
        font-size: 60px;
        line-height: 70px;
   }
    h2 {
        font-size: 38px;
        line-height: 48px;
   }
    h3 {
        font-size: 36px;
        line-height: 46px;
   }
    h4 {
        font-size: 32px;
        line-height: 42px;
   }
    h5 {
        font-size: 30px;
        line-height: 40px;
   }
    h6 {
        font-size: 26px;
        line-height: 36px;
   }
    .search-block .tags-wrapper .tags .single-tag:nth-child(3), .search-block .tags-wrapper .tags .single-tag:nth-child(4), .search-block .tags-wrapper .tags .single-tag:nth-child(5) {
        display: none;
   }
    .footer .container .footer-content {
        flex-wrap: wrap;
   }
    .footer .container .footer-content .col {
        width: 25%;
        margin-bottom: 35px;
   }
    .footer .container .footer-content .col button {
        min-width: 185px;
   }
    .footer .container .certifications{
        flex-direction: column;
        align-items: flex-start;
   }
    .footer .container .certifications .left{
        padding-right: 0;
        margin-bottom: 30px;
   }
    .footer .container .certifications .right{
        width: 100%;
   }
    .footer .container .certifications .right > .logo {
        padding: 0 50px;
   }
    section.products .container > .categories-wrapper .single-category-wrapper .category {
        min-height: 450px;
   }
    section.about-section .container > div.left-column .image-block:first-child {
        margin-bottom: 70px;
   }
    section.about-section .container > div.left-column .image-block:first-child .image {
        height: 500px;
   }
    section.about-section .container > div.left-column .image-block:nth-child(2) .image {
        height: 450px;
   }
    section.about-section .container > div.right-column .image-block .image {
        height: 400px;
   }
    section.about-section .container > div.right-column .text-block {
        margin-bottom: 50px;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image {
        height: 400px;
   }
    section.team .container .team-wrapper .categories .category:not(:last-child) {
        margin-right: 50px;
   }
    section.about-body-section .container > div.upper {
        flex-direction: column;
        margin-bottom: 60px;
   }
    section.about-body-section .container > div.upper .image {
        height: 450px;
        margin-bottom: 30px;
   }
    section.about-body-section .container > div.bottom {
        flex-direction: column-reverse;
   }
    section.about-body-section .container > div.bottom .image {
        height: 450px;
        margin-bottom: 63px;
   }
    section.about-body-section .container > div > div {
        width: 100%;
        padding: 0 !important;
   }
    section.team .container .careers-block .right, section.team .container .careers-block .left {
        padding: 45px 30px;
   }
    section.team .container .careers-block .right .careers-information .title-style-1 {
        font-size: 40px;
        line-height: 50px;
   }
    section.team .container .careers-block .right .careers-information .title-style-2 {
        font-size: 55px;
        line-height: 65px;
   }
    section.certifications-section .container .certifications-wrapper .single-certification-wrapper .single-certification {
        padding: 40px 25px 130px 25px;
   }
    .catalog-body::before {
        display: none;
   }
    .catalog-body .container {
        flex-direction: column;
        width: 100%;
   }
    .catalog-body .container .sidebar {
        width: 100%;
        max-width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 40px;
   }
    .catalog-body .container .sidebar::before {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #0A1E5B;
   }
    .catalog-body .container .main-content {
        padding: 40px 20px;
        width: 100%;
   }
    .catalog-body .container .sidebar .action .btn-wrapper {
        flex-direction: row;
   }
    .catalog-body .container .sidebar .action .btn-wrapper button:first-child {
        margin-bottom: 0;
        margin-right: 15px;
   }
    .catalog-body .container .main-content .selected-filters-wrapper {
        top: 100px;
   }
    section.contact-body .container {
        padding-top: 200px;
   }
    section.contact-body .container .main-content {
        flex-direction: column;
   }
    section.contact-body .container .main-content > div {
        padding: 0 !important;
        width: 100% !important;
   }
    section.contact-body .container .main-content > div:first-child {
        margin-bottom: 40px;
   }
    section.contact-details .container .locations .single-detail-wrapper, section.contact-details .container .contact-info .single-detail-wrapper {
        width: 50%;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result {
        flex-direction: column;
        padding: 35px 40px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .image {
        width: 200px;
        margin-right: 0;
        margin-bottom: 25px;
        min-width: 200px;
   }
    section.faqs-body .faqs-wrapper {
        margin-top: 30px;
   }
    section.technical-body section.data .container {
        padding-top: 50px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data, section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data {
        width: 50%;
   }
    section.technical-body .navigation-sticky-container {
        top: 200px;
   }
    section.technical-body .navigation-sticky-container .navigation-wrapper .navigation .single-navigation {
        font-size: 20px;
        line-height: 30px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data {
        padding: 0 10px;
        margin-bottom: 20px;
   }
    .news-body .container, .news-single-body.news-video .container {
        padding-top: 150px;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a .date {
        left: 15px;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a .category {
        right: 15px;
   }
    .news-single-body .container .banner {
        padding: 30px;
        min-height: 400px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper {
        flex-direction: column-reverse !important;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper > div {
        padding: 0 !important;
        width: 100% !important;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper > div.image-block {
        height: 450px;
        margin-bottom: 30px;
   }
    .single-product-body section.overview .container {
        flex-wrap: wrap;
   }
    .single-product-body section.overview .container .gallery {
        width: 70%;
        min-width: 70%;
        margin-right: 0;
        margin-bottom: 100px;
   }
    .single-product-body section.overview .container .actions {
        width: 45%;
   }
    .single-product-body section.overview .container .overview-text {
        width: 55%;
   }
    .single-product-body section.overview .container .product-image-zoom {
        width: 100%;
        height: 500px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper {
        width: 50%;
   }
    .single-product-body section.specifications .container .cta {
        align-items: flex-end;
   }
    .single-product-body .table-container table thead > tr th {
        font-size: 16px;
        line-height: 24px;
        padding: 10px;
   }
    .single-product-body .table-container table tbody > tr td {
        font-size: 15px;
        line-height: 24px;
   }
}
@media (max-width: 767px) {
    .header .main-header .container .right .cart .cart-count {
        right: -10px;
   }
    h1, section.entry-banner .container .text-content h1, section.products .container > h1 {
        font-size: 45px;
        line-height: 55px;
   }
    h2 {
        font-size: 34px;
        line-height: 44px;
   }
    h3 {
        font-size: 32px;
        line-height: 42px;
   }
    h4 {
        font-size: 28px;
        line-height: 38px;
   }
    h5 {
        font-size: 26px;
        line-height: 36px !important;
   }
    h6 {
        font-size: 24px;
        line-height: 34px;
   }
    .search-modal .container .head {
        margin-bottom: 60px;
   }
    .search-block {
        padding: 45px 30px;
   }
    .search-block .search-container .search-results {
        padding: 10px 30px 20px 30px;
   }
    .search-block .search-container .search-results .suggestion {
        padding: 15px 0;
   }
    .search-block .search-container .search-results .suggestion .image {
        width: 50px;
        height: 50px;
        margin-right: 20px;
   }
    .search-block .search-container .search-results .suggestion .desc .title {
        font-size: 22px;
        line-height: 32px;
   }
    .header .main-header .container .right .cart .cart-modal {
        min-width: 520px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-body {
        padding: 0 20px 24px 20px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-body .cart-item {
        padding: 15px 0;
   }
    .header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .title {
        margin-bottom: 3px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-body .cart-item:hover {
        padding: 15px 10px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-footer {
        padding: 22px 20px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-footer button a {
        padding: 15px 25px;
   }
    .footer .container {
        padding-top: 50px;
   }
    .footer .container .certifications {
        flex-direction: column;
        align-items: flex-start;
   }
    .footer .container .certifications .left {
        padding-right: 0;
        margin-bottom: 40px;
   }
    .footer .container .certifications .right {
        padding-left: 0;
        width: 100%;
   }
    .footer .container .certifications .right .logo {
        padding: 0 28px;
   }
    .footer .container .footer-content .col {
        width: 33.333%;
   }
    .footer .container .footer-content .col button {
        min-width: 100%;
   }
    section.resources .container .resources-wrapper {
        margin-left: -7px;
        margin-right: -7px;
        margin-bottom: 20px;
   }
    section.resources .container .resources-wrapper .single-resource-wrapper .resource > a {
        padding: 30px 20px 27px;
   }
    section.resources .container .resources-wrapper .single-resource-wrapper .resource > a > svg {
        width: calc(100% - 15px);
        height: calc(100% - 15px);
   }
    section.resources .container .cta {
        flex-direction: column;
        align-items: flex-start;
   }
    section.resources .container .cta .left {
        margin-bottom: 30px;
   }
    section.products .container > .categories-wrapper .single-category-wrapper, section.resources .container .resources-wrapper .single-resource-wrapper {
        padding: 0 7px;
        margin-bottom: 14px;
   }
    section.about-section .container {
        flex-direction: column-reverse;
   }
    section.about-section .container > div {
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
   }
    section.about-section .container > div .image {
        height: 400px !important;
   }
    section.about-section .container > div.right-column {
        margin-bottom: 80px;
   }
    section.about-section .container > div.right-column .text-block {
        margin-bottom: 80px;
   }
    section.about-section .container > div.left-column .image-block:first-child {
        margin-bottom: 80px;
   }
    section.about-section .container > div.left-column .image-block:nth-child(2) {
        width: 100%;
   }
    section.news-slider .container .text-content {
        margin-bottom: 100px;
   }
    section.news-slider .container .swiper .swiper-button-next, section.news-slider .container .swiper .swiper-button-prev {
        top: -60px;
   }
    section.entry-banner .container .text-content .text {
        font-size: 22px;
        line-height: 32px;
   }
    section.about-body-section .container > div.upper .image, section.about-body-section .container > div.bottom .image {
        height: 400px;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper {
        width: 100%;
   }
    section.team .container .team-wrapper .categories .category {
        font-size: 20px;
        line-height: 30px;
   }
    section.team .container .careers-block {
        flex-direction: column;
   }
    section.team .container .careers-block > div {
        width: 100% !important;
   }
    section.team .container .careers-block > div.left {
        padding-bottom: 100px;
   }
    section.certifications-section .container .certifications-wrapper .single-certification-wrapper {
        width: 100%;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper {
        width: 50%;
   }
    section.contact-body .container {
        padding-top: 150px;
   }
    section.contact-body .container .main-content .right .form-wrapper {
        padding: 40px 25px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result {
        padding: 30px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn a {
        padding: 15px 20px;
   }
    .cross-reference-body .search-sticky-container.is-fixed h3 {
        font-size: 24px;
        line-height: 34px;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__question .question {
        font-size: 24px;
        line-height: 34px;
   }
    section.faqs-body .faqs-wrapper .faq-item {
        padding: 30px 25px;
   }
    .subscription-download-form .container .form-wrapper {
        padding: 50px 40px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data {
        padding: 45px 50px;
   }
    .news-body .container > div.sidebar-wrapper {
        width: 100%;
   }
    .news-body .container .sidebar-wrapper .sidebar {
        padding: 30px;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper {
        width: 100%;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper .single-news h6 {
        font-size: 20px;
        line-height: 29px;
        letter-spacing: 1px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper > div.image-block {
        height: 400px;
   }
    .navigation-sticky-container .navigation-wrapper .navigation .single-navigation {
        font-size: 18px;
        line-height: 28px;
   }
    .navigation-sticky-container.is-sticky .navigation-wrapper .navigation .single-navigation {
        font-size: 17px;
        line-height: 27px;
   }
    .navigation-sticky-container .navigation-wrapper .navigation .single-navigation:not(:last-child) {
        margin-right: 30px;
   }
    .single-product-body section.overview .container .gallery, .single-product-body section.overview .container .overview-text {
        width: 100%;
   }
    .single-product-body section.overview .container .actions {
        margin-top: 40px;
        width: 70%;
   }
    .single-product-upper .container {
        padding-top: 150px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper {
        width: 100%;
   }
    .single-product-body section.technical-info .container .cta {
        flex-direction: column;
   }
    .single-product-body section.technical-info .container .cta .left {
        align-items: unset;
        margin-bottom: 20px;
   }
    .single-product-body section.technical-info .container .cta .left img {
        margin-top: 5px;
        height: fit-content;
   }
    .single-product-body section.technical-info .container .cta button {
        width: fit-content;
   }
}
.single-product-body section.technical-info .container .table-container table {
    margin-top: 10px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data {
    padding:20px !important;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data svg {
    max-width: 100px;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data .divider {
    margin: 25px 0;
}
section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data .title {
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 28px;
}
@media (max-width: 575px) {
    .header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .no-variations span {
        font-size: 14px;
   }
    .catalog-body .container .sidebar .category-block-wrapper > .category-blocks .category-block.sub-category:not(:last-child) {
        margin-bottom: 20px;
   }
    .catalog-body .container .sidebar .category-block-wrapper > .category-blocks .category-block.sub-category .body {
        margin-top: 5px;
   }
    .single-product-body section.specifications.has-table .container .table-specifications-wrapper > div .single-table-specification__text ul li {
        font-size: 16px;
        line-height: 20px;
   }
    .single-product-body section.overview .container .actions .block {
        min-width: 100%;
   }
    .single-product-body section.specifications.has-table .container .table-specifications-wrapper > div:not(:last-child) {
        margin-bottom: 50px;
   }
    .single-product-body section.specifications.has-table .container .table-specifications-wrapper > div h3 {
        font-size: 22px;
        line-height: 32px;
   }
    .single-product-body section.specifications.has-table .container h2 {
        margin-bottom: 20px;
   }
    section.entry-banner.hp .swiper .swiper-slide > img.mobile-bg {
        display: block;
   }
    section.entry-banner.hp .swiper .swiper-slide > img.desktop-bg {
        display: none;
   }
    section.entry-banner.hp .swiper .swiper-slide .text-content .discover span {
        font-size: 14px;
        line-height: 24px;
   }
    .header .main-header .container .right .cart .cart-count {
        width: 24px;
        height: 24px;
   }
    .not-found-body .container .illustration {
        max-width: 300px;
   }
    section.legal-body .container .text-content ul li, .news-single-body .container .text-content ul li, section.legal-body .container .text-content ol li, .news-single-body .container .text-content ol li {
        font-size: 18px;
        line-height: 24px;
   }
    .header .main-header .container .right .cart .cart-tooltip {
        left: -125px;
   }
    .header .main-header .container .right .cart .cart-tooltip::after {
        left: unset;
        right: 19px;
   }
    .header .main-header .container .right .cart .cart-modal {
        min-width: 340px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .title {
        font-size: 18px;
        line-height: 28px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .category {
        font-size: 14px;
        line-height: 24px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-footer {
        flex-direction: column;
   }
    .header .main-header .container .right .cart .cart-modal .cart-footer button.view {
        margin-right: 0;
        margin-bottom: 20px;
   }
    .mobile-nav .nav__content > ul > li a {
        font-size: 32px;
        line-height: 42px;
   }
    .mobile-nav .nav__content > ul > li.menu-l > .sub-menu > li > a {
        font-size: 28px;
        line-height: 38px;
        padding-right: 25px;
   }
    .footer .container {
        padding-bottom: 20px;
   }
    .footer .container .certifications {
        margin-bottom: 80px;
        padding-bottom: 30px;
   }
    .footer .container .certifications .right .logo {
        padding: 0 15px;
   }
    .footer .container .bg-logo {
        max-width: 100%;
   }
    .footer .container .bg-logo img {
        max-width: 100%;
   }
    .footer .container .footer-content > div.mobile-logo {
        display: block;
        margin: 0 auto;
        margin-bottom: 40px;
   }
    .footer .container .footer-content > div:last-child {
        order: 1;
        -webkit-order: 1;
        width: 100%;
        align-items: center;
        margin-bottom: 50px;
   }
    .footer .container .footer-content > div:last-child button {
        min-width: 300px;
   }
    .footer .container .footer-content > div:nth-child(3) {
        order: 2;
        -webkit-order: 2;
   }
    .footer .container .footer-content > div:nth-child(4) {
        order: 3;
        -webkit-order: 3;
   }
    .footer .container .footer-content > div:nth-child(5) {
        order: 4;
        -webkit-order: 4;
   }
    .footer .container .footer-content > div:nth-child(6) {
        order: 5;
        -webkit-order: 5;
   }
    .footer .container .footer-content > div:nth-child(2) {
        order: 6;
        -webkit-order: 6;
        width: 100%;
        padding: 0 10px;
        margin-bottom: 0;
        margin-top: 40px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
   }
    .footer .container .footer-content .col {
        width: 50%;
   }
    .footer .container .footer-content .col .logo {
        display: none;
   }
    .container {
        padding: 75px 0;
   }
    h1, section.entry-banner .container .text-content h1, section.products .container > h1, .footer .container .certifications .left .title {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 3px;
   }
    h2 {
        font-size: 28px;
        line-height: 38px;
        letter-spacing: 1.4px;
   }
    h3 {
        font-size: 26px;
        line-height: 36px;
   }
    h4 {
        font-size: 24px;
        line-height: 34px;
   }
    h5 {
        font-size: 22px;
        line-height: 32px !important;
   }
    h6 {
        font-size: 20px;
        line-height: 30px;
   }
    p {
        font-size: 18px;
        line-height: 28px;
   }
    .search-block {
        padding: 32px 25px;
   }
    .search-block .search-container input {
        padding: 17px 62px 17px 20px;
        font-size: 16px;
   }
    .search-block .search-container button.submit {
        right: 15px;
   }
    .search-block .search-container .search-results {
        padding: 10px 15px 20px 15px;
   }
    .search-block .search-container .search-results .suggestion .desc .title {
        font-size: 18px;
        line-height: 28px;
   }
    .search-block .search-container .search-results .suggestion .desc .category {
        font-size: 16px;
        line-height: 26px;
   }
    .search-block .tags-wrapper {
        flex-direction: column;
        align-items: flex-start;
   }
    .search-block .tags-wrapper .label {
        margin-right: 0;
        margin-bottom: 9px;
   }
    .search-block .tags-wrapper .tags {
        flex-wrap: wrap;
   }
    .search-block .tags-wrapper .tags .single-tag {
        margin-bottom: 14px;
   }
    .navigation-sticky-container .navigation-wrapper .navigation .single-navigation {
        font-size: 16px !important;
        padding-bottom: 15px;
        line-height: 24px !important;
   }
    .search-sticky-container.is-fixed .search-block {
        padding: 12px 0;
   }
    .single-product-body section.overview .container .product-image-zoom {
        width: 100%;
        height: 400px;
   }
    .single-product-body section.specifications .container h2, .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification h4 {
        margin-bottom: 20px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification p, .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ol li {
        font-size: 16px;
        line-height: 24px;
   }
    .single-product-body section.technical-info .container > .text, .single-product-body section.technical-info .container .cta .left span, .single-product-body section.technical-info .container .single-table-notes__text ul li {
        font-size: 18px;
        line-height: 26px;
   }
    .single-product-body section.technical-info .container .table-container table {
        margin-top: 30px;
   }
    .single-product-body section.technical-info .container .single-table-notes__text {
        padding-left: 20px;
   }
    .single-product-body section.technical-info .container .single-table-notes__text ol li{
        font-size: 16px;
        line-height: 20px;
        padding-left: 0;
   }
    .single-product-body section.technical-info .container ul li, .single-product-body section.specifications.has-table .container ul li {
        font-size: 16px;
        line-height: 20px;
        padding-left: 25px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification .illustration {
        margin-bottom: 20px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification .illustration img {
        max-width: 50px;
   }
   .single-product-body section.technical-info .container >.title {
        font-size: 22px;
        line-height: 32px;
   }
    .single-product-body .table-container table thead > tr th, .single-product-body .table-container table tbody > tr td {
        font-size: 14px;
        line-height: 22px;
   }
    .single-product-body .table-container table tbody > tr td {
        padding: 5px;
   }
    .single-product-body section.specifications .container .cta {
        flex-direction: column;
        align-items: flex-start;
   }
    .single-product-body section.specifications .container .cta .left {
        padding-right: 0;
        margin-bottom: 20px;
   }
    .single-product-body section.overview .container .product-image-zoom .product-image-wrapper .zoom-out {
        right: 15px;
        top: 15px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ul {
        margin-bottom: 0px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ul li {
        font-size: 16px;
        line-height: 24px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ul li::before {
        top: 6px;
   }
    .single-product-body section.specifications .container .specifications-wrapper .single-specification-wrapper .single-specification ul li:not(:last-child) {
        margin-bottom: 10px;
   }
    .single-product-body section.overview .container .actions {
        width: 100%;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper > div.image-block {
        height: 300px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block button {
        margin-top: 20px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block h1 {
        margin-bottom: 15px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block p {
        font-size: 16px;
        line-height: 26px;
   }
    section.solutions-body .navigation-sticky-container .navigation-wrapper .navigation {
        flex-wrap: wrap;
   }
    section.solutions-body .navigation-sticky-container .navigation-wrapper .navigation .single-navigation {
        margin-bottom: 10px;
        padding-bottom: 5px;
   }
    section.solutions-body .navigation-sticky-container .navigation-wrapper .navigation .single-navigation::before {
        height: 2px;
   }
    .news-body .container .main-content h1 {
        margin-bottom: 20px;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper{
        width: 100%;
        padding: 0;
   }
    .news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a {
        height: 300px;
        margin-bottom: 20px;
   }
    .news-single-body .container .banner {
        padding: 20px;
   }
    .news-single-body .container .text-content p {
        font-size: 18px;
        line-height: 28px;
   }
    .news-single-body .container .text-content {
        margin-bottom: 50px;
   }
    .news-single-slider .container h2 {
        margin-bottom: 100px;
   }
    .news-single-body .container .banner .text h2{
        font-size: 24px;
        line-height: 34px;
   }
    .news-single-body .container .banner .category {
        right: 20px;
        top: 20px;
        font-size: 16px;
   }
    section.news-slider.news-single-slider .container .swiper .swiper-button-next, section.news-slider.news-single-slider .container .swiper .swiper-button-prev {
        top: -60px;
   }
    section.faqs-body {
        padding-top: 75px;
        padding-bottom: 75px;
   }
    section.faqs-body .cta {
        padding-top: 30px;
        flex-direction: column;
   }
    section.faqs-body .cta .text {
        padding-right: 0;
        margin-bottom: 20px;
   }
    section.faqs-body .faqs-wrapper .faq-item:not(:last-child) {
        margin-bottom: 20px;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__question .circle {
        width: 40px;
        height: 40px;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__question .question {
        font-size: 20px;
        line-height: 30px;
   }
    section.faqs-body .faqs-wrapper {
        margin-bottom: 40px;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer {
        flex-direction: column;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-footer .keywords {
        padding-right: 0;
        margin-bottom: 20px;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-body p {
        font-size: 16px;
        line-height: 24px;
   }
    .cross-reference-body {
        padding-top: 75px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-footer {
        flex-direction: column;
   }
    .cross-reference-body .search-sticky-container.is-fixed h3 {
        font-size: 20px;
        line-height: 30px;
   }
    .cross-reference-body .container .search-tips-block {
        margin-bottom: 40px;
        padding: 20px;
   }
    .cross-reference-body .container .search-tips-block .content .bottom ul li {
        font-size: 16px;
        line-height: 26px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .image {
        width: 150px;
        min-width: 150px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta {
        margin-top: 20px;
        padding-top: 20px;
        flex-wrap: wrap;
        justify-content: space-between;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .add-to-favorites {
        width: 100%;
        margin-bottom: 10px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta button {
        width: 48%;
        margin-right: 0 !important;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta .outline-btn a {
        padding: 15px 12px;
        font-size: 15px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result {
        padding: 20px;
   }
    .cross-reference-body .container .search-tips-block .head .circle {
        width: 40px;
        height: 40px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-footer .left {
        margin-bottom: 20px;
   }
    .cross-reference-body .container .search-tips-block .content {
        margin-left: 0;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .results-found {
        font-size: 22px;
        line-height: 32px;
   }
    section.technical-body {
        padding-top: 75px;
   }
    section.technical-body section.data .container .btn-wrapper {
        flex-direction: column;
        margin-top: 25px;
   }
    section.technical-body section.data .container .btn-wrapper button {
        width: fit-content;
   }
    section.technical-body section.data .container .btn-wrapper button:first-child {
        margin-right: 0;
        margin-bottom: 20px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data .title-wrapper .title {
        font-size: 20px;
        line-height: 30px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data {
        min-height: 300px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data .single-data .title-wrapper {
        padding: 20px 25px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data {
        padding: 30px;
   }
    section.technical-body .navigation-sticky-container {
        top: 172px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data svg {
        max-width: 80px;
        height: auto;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data .single-data .divider {
        margin: 20px 0;
   }
    .search-sticky-container {
        height: 150px;
   }
    section.technical-body section.data .container .data-wrapper .single-data-wrapper.literature-data, section.technical-body section.data .container .data-wrapper .single-data-wrapper.technical-data {
        width: 100%;
   }
    .subscription-download-form .container .form-wrapper {
        padding: 30px;
   }
    .subscription-download-form .container .form-wrapper .title {
        font-size: 18px;
        line-height: 28px;
   }
    .subscription-download-form .container .form-wrapper form .input-block {
        margin-bottom: 20px;
   }
    .subscription-download-form .container .form-wrapper form label {
        font-size: 16px;
        line-height: 24px;
   }
    .subscription-download-form .container .form-wrapper form .terms {
        width: 100%;
        margin-bottom: 20px;
   }
    .catalog-body .container .sidebar {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 140px;
        padding-bottom: 20px;
   }
    .catalog-body .container .sidebar .action {
        margin-bottom: 20px;
   }
    .catalog-body .container .sidebar .category-block-wrapper .category-blocks .category-block .head .title {
        font-size: 20px;
        line-height: 30px;
   }
    .catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .body label {
        font-size: 16px;
        line-height: 24px;
        margin-left: 10px;
   }
    .catalog-body .container .sidebar .category-block-wrapper .category-blocks .category-block .head {
        padding-bottom: 15px;
   }
    .catalog-body .container .sidebar .category-block-wrapper > .category-blocks > .category-block .body input {
        width: 20px;
        height: 20px;
   }
    .catalog-body .container .sidebar .category-block-wrapper>.category-blocks>.category-block:not(:last-child){
        margin-bottom: 30px;
   }
    .catalog-body .container .sidebar .category-block-wrapper>.category-blocks>.category-block .body input {
        min-width: 20px;
   }
    .catalog-body .container .main-content {
        padding: 40px 10px 
   }
    .catalog-body .container .main-content > .text {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block:not(:last-child) {
        margin-bottom: 10px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .head .upper {
        margin-bottom: 10px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper {
        width: 100%;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .product-image {
        max-width: 100px;
   }
    .catalog-body .container .main-content .selected-filters .selected-filter {
        margin-bottom: 10px;
        padding: 5px 8px;
   }
    .catalog-body .container .main-content .selected-filters .selected-filter:not(:last-child) {
        margin-right: 10px;
   }
    .catalog-body .container .main-content .selected-filters .selected-filter span {
        font-size: 12px;
        line-height: 18px;
   }
    .catalog-body .container .catalog-category-custom-margin >.text{
        margin-bottom: 20px;
   }
    .catalog-body .container .main-content .catalog-category-custom-margin .main-text {
        font-size: 16px;
        line-height: 26px;
   }
    .catalog-body .container .main-content .catalog-category-custom-margin h1 {
        margin-bottom: 10px;
   }
    .catalog-body .container .main-content .catalog-category-custom-margin .category-level-two {
        padding: 15px;
   }
    .catalog-body .container .main-content .catalog-category-custom-margin .category-level-two .cl2-text, .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body>.description {
        font-size: 16px;
        line-height: 26px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body .products-wrapper .single-product-wrapper .single-product .product-image {
        height: 100px;
   }
    .catalog-body .container .main-content .selected-filters-wrapper .sf-title {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 5px;
   }
    .catalog-body .container .main-content .catalog-category-custom-margin .category-level-two h3 {
        margin-bottom: 10px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .head h6 {
        font-size: 20px;
        line-height: 30px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .head .upper {
        margin-bottom: 0;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block:not(:last-child) {
        margin-bottom: 30px;
   }
    .single-product-body section.overview .container .actions .block{
        min-width: 100%;
   }
    section.contact-body .container .main-content .left p, section.contact-details .container .single-detail-wrapper .single-detail p, section.contact-body .container .main-content .left .blocks .block a span {
        font-size: 18px;
        line-height: 28px;
   }
    section.contact-body .container .main-content .right .form-wrapper h2 {
        margin-bottom: 20px;
   }
    section.contact-body .container .main-content {
        margin-bottom: 40px;
   }
    section.contact-body .container .main-content .left .blocks .block a img:not(.arrow) {
        max-width: 30px;
        max-height: 39px;
   }
    section.contact-body .container .main-content .left .blocks .block a svg:first-child {
        max-width: 30px;
        max-height: 39px;
   }
    section.contact-details .container h2 {
        margin-bottom: 25px;
   }
    section.contact-details .container .single-detail-wrapper {
        padding: 0;
   }
    section.contact-body .container .main-content .right .form-wrapper form .input-block:not(.full) {
        width: 100%;
   }
    section.contact-details .container .locations .single-detail-wrapper, section.contact-details .container .contact-info .single-detail-wrapper {
        width: 100%;
   }
    section.contact-body .container .main-content .right .form-wrapper form .input-block {
        margin-bottom: 14px;
   }
    section.contact-body .container .main-content .right .form-wrapper form .input-block input, section.contact-body .container .main-content .right .form-wrapper form .input-block textarea, section.contact-body .container .main-content .right .form-wrapper form .input-block select {
        font-size: 14px;
        line-height: 20px;
        padding: 10px;
   }
    section.contact-body .container .main-content .right .form-wrapper form .consent {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
   }
    section.contact-body .container .main-content .right .form-wrapper form .input-block label {
        font-size: 16px;
        line-height: 26px;
   }
    section.contact-body .container .main-content .left h1 {
        margin-bottom: 20px;
   }
    section.contact-body .container .main-content .left .blocks .block a {
        padding: 20px;
   }
    section.team .container .careers-block .right .careers-information button a span {
        font-size: 16px;
        line-height: 26px;
   }
    section.entry-banner .container .text-content .text, section.certifications-section .container .text-block > p {
        font-size: 18px;
        line-height: 28px;
   }
    section.certifications-section .container .certifications-wrapper {
        margin-top: 40px;
   }
    section.certifications-section .container .certifications-wrapper .single-certification-wrapper .single-certification .logo {
        max-width: 100px;
        margin-bottom: 20px;
   }
    section.entry-banner:not(.hp) .container .text-content h1 {
        margin-bottom: 20px;
   }
    section.entry-banner:not(.hp)::before {
        background-color: rgba(0, 0, 0, 0.6);
   }
    section.about-body-section .container > div.upper .image, section.about-body-section .container > div.bottom .image {
        height: 300px;
   }
    section.about-body-section .container > div > div .block > div .color-text {
        font-size: 20px;
        line-height: 30px;
   }
    section.about-body-section .container > div.bottom .text-block h2 {
        margin-bottom: 30px;
   }
    section.about-body-section .container > div.bottom .image {
        margin-bottom: 30px;
   }
    section.about-body-section .container > div.bottom .text-block p strong, section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .information .name {
        font-size: 20px;
        line-height: 20px;
   }
    section.team .container .text-block h1 {
        margin-bottom: 20px;
   }
    section.team .container .text-block .text, section.team .container .team-wrapper .categories .category {
        font-size: 18px;
        line-height: 28px;
   }
    section.team .container .text-block, section.team .container .team-wrapper .categories {
        margin-bottom: 40px;
   }
    section.team .container .team-wrapper .categories .category:not(:last-child) {
        margin-right: 30px;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image {
        height: 300px;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .information .position {
        font-size: 16px;
        line-height: 26px;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper {
        margin-bottom: 35px;
   }
    section.team .container .careers-block .right, section.team .container .careers-block .left {
        padding: 25px;
   }
    section.team .container .careers-block .right .careers-information .title-style-1 {
        font-size: 25px;
        line-height: 35px;
   }
    section.team .container .careers-block .right .careers-information .title-style-2 {
        font-size: 30px;
        line-height: 40px;
   }
    section.entry-banner.hp .swiper .swiper-slide .text-content .discover {
        margin-bottom: 90px;
   }
    section.news-slider .container .swiper .swiper-slide .inner-content .upper {
        margin-bottom: 50px;
   }
    section.resources .container > .text, section.resources .container .resources-wrapper .single-resource-wrapper .resource .text, section.news-slider .container .text-content .text {
        font-size: 18px;
        line-height: 28px;
   }
    section.about-section .container > div.right-column .text-block h1, section.news-slider .container .text-content h1 {
        margin-bottom: 20px;
   }
    section.products .container > .categories-wrapper .single-category-wrapper, section.resources .container .resources-wrapper .single-resource-wrapper {
        width: 100%;
        padding: 0;
   }
    section.about-section .container > div .image-block .image {
        height: 350px !important;
   }
    section.about-section .container > div.right-column, section.about-section .container > div.left-column .image-block:first-child {
        margin-bottom: 50px;
   }
    section.entry-banner::before {
        background-color: rgba(0, 0, 0, 0.5);
   }
    section.entry-banner.hp .scroll {
        display: none;
   }
    section.products .container > h1 {
        margin-bottom: 40px;
   }
    section.products .container > .categories-wrapper .single-category-wrapper .category {
        min-height: 365px;
   }
    section.resources .container .cta {
        align-items: center;
   }
    section.resources .container .cta .left {
        font-size: 20px;
        line-height: 30px;
   }
    section.resources .container .resources-wrapper {
        margin-left: 0;
        margin-right: 0;
   }
    section.resources .container .resources-wrapper .single-resource-wrapper .resource .illustration {
        max-height: 70px;
   }
    section.resources .container .resources-wrapper .single-resource-wrapper .resource h4 {
        margin-bottom: 10px;
   }
    section.news-slider .container .swiper .swiper-slide {
        padding: 20px;
   }
    section.news-slider .container .swiper .swiper-slide .inner-content .bottom {
        -webkit-transform: translateY(70px);
        -moz-transform: translateY(70px);
        -ms-transform: translateY(70px);
        -o-transform: translateY(70px);
        transform: translateY(70px);
   }
}
@media (max-width: 399px) {
    .single-product-body section.specifications.has-table .container .table-specifications-wrapper > div h3 {
        font-size: 20px;
        line-height: 30px;
   }
    .not-found-body .container {
        padding-top: 100px;
   }
    .not-found-body .container .illustration {
        max-width: 200px;
   }
    section.entry-banner.hp .swiper .swiper-slide .text-content .discover {
        min-width: 100%;
   }
    section.legal-body .container .text-content ol li, .news-single-body .container .text-content ol li {
        padding-left: 7px;
   }
    section.legal-body .container .text-content ul, .news-single-body .container .text-content ul {
        padding-left: 10px;
   }
    section.legal-body .container .text-content ul li, section.legal-body .container .text-content ol li {
        font-size: 16px;
        line-height: 24px;
   }
    .news-single-body .container .text-content ul li, .news-single-body .container .text-content ol li {
        font-size: 14px;
        line-height: 20px;
   }
    .container {
        padding: 50px 0;
   }
    .mobile-nav .nav__content > ul > li.menu-l > .sub-menu > li > a {
        padding-left: 25px;
   }
    .mobile-nav .nav__content > ul > li a {
        font-size: 26px;
        line-height: 36px;
   }
    .mobile-nav .nav__content > ul > li.menu-l > .sub-menu > li > a {
        font-size: 24px;
        line-height: 34px;
   }
    .mobile-nav .nav__content > ul > li.menu-l > .sub-menu ul a {
        font-size: 19px;
        line-height: 29px;
   }
    .header.banner-header .main-header .container {
        padding: 15px 10px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-body {
        padding: 0 15px 24px 15px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .title {
        font-size: 16px;
        line-height: 26px;
   }
    .header .main-header .container .right .cart .cart-modal .cart-body .cart-item .desc .category {
        line-height: 19px;
   }
    .header .main-header .container .right .cart .cart-modal {
        min-width: 284px;
   }
    .search-block {
        padding: 32px 15px;
   }
    .search-block .search-container input {
        padding: 15px 38px 15px 12px;
        font-size: 14px;
   }
    .search-block .search-container input::placeholder {
        font-size: 14px;
   }
    .search-block .search-container button.submit {
        max-width: 22px;
   }
    .search-block .search-container button.submit img {
        max-width: 100%;
   }
    .search-block .search-container .search-results .suggestion .image {
        margin-right: 10px;
   }
    .search-block .search-container .search-results .suggestion .desc .title {
        font-size: 16px;
        line-height: 26px;
   }
    .search-block .search-container .search-results .suggestion .desc .category {
        font-size: 14px;
        line-height: 19px;
   }
    .footer .container .certifications .right .logo {
        padding: 0 15px;
   }
    .footer .container .footer-content > div:last-child button {
        min-width: 250px;
   }
    p {
        font-size: 16px;
        line-height: 24px;
   }
    section.news-slider .container .swiper .swiper-button-next {
        right: 20px;
   }
    section.technical-body section.data .container p, section.contact-body .container .main-content .right .form-wrapper p, section.contact-body .container .main-content .left p, section.contact-details .container .single-detail-wrapper .single-detail p, section.contact-body .container .main-content .left .blocks .block a span, .catalog-body .container .main-content > .text, .catalog-body .container .sidebar .category-block-wrapper .category-blocks .category-block .head .title, section.certifications-section .container .certifications-wrapper .single-certification-wrapper .single-certification p, section.entry-banner .container .text-content .text, section.certifications-section .container .text-block > p, section.team .container .text-block .text, section.team .container .team-wrapper .categories .category {
        font-size: 16px;
        line-height: 24px;
   }
    section.team .container .team-wrapper .categories .category:not(:last-child) {
        margin-right: 20px;
   }
    section.team .container .team-wrapper .team-members .single-team-member-wrapper .single-team-member .image, section.about-body-section .container > div.upper .image, section.about-body-section .container > div.bottom .image {
        height: 250px;
   }
    section.team .container .team-wrapper .team-members {
        margin-bottom: 20px;
   }
    section.certifications-section .container .certifications-wrapper .single-certification-wrapper .single-certification .logo {
        max-width: 80px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper .text-block p, .news-single-body .container .text-content p, .news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a .date, .news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a .category, section.faqs-body .faqs-wrapper .faq-item .faq-item__answer .answer-wrapper .answer-body p, .cross-reference-body .container .search-tips-block .content .bottom ul li, .cross-reference-body .container .search-tips-block .content p, .catalog-body .container .sidebar .action .btn-wrapper button a span, section.contact-body .container .main-content .right .form-wrapper form .consent {
        font-size: 14px;
        line-height: 24px;
   }
    .catalog-body .container .sidebar .category-block-wrapper .category-blocks .category-block:not(:last-child) {
        margin-bottom: 20px;
   }
    .catalog-body .container .sidebar .action .btn-wrapper {
        flex-direction: column;
   }
    .catalog-body .container .sidebar .action .btn-wrapper button {
        width: fit-content;
   }
    .catalog-body .container .sidebar .action .btn-wrapper button:first-child {
        margin-right: 0;
        margin-bottom: 15px;
   }
    section.solutions-body .container .solutions-wrapper .single-solution-wrapper > div.image-block {
        height: 250px;
   }
    section.contact-body .container .map {
        height: 300px;
   }
    .cross-reference-body .container .search-tips-block .content .bottom {
        margin-top: 20px;
        padding-top: 20px;
   }
    section.contact-details .container .contact-info .single-detail-wrapper .single-detail, section.contact-details .container .locations .single-detail-wrapper .single-detail a {
        padding: 30px 20px;
   }
    .single-product-body section.technical-info .container .single-table-notes__text ul li, .single-product-body section.overview .container .overview-text p, .cross-reference-body .search-sticky-container .search-block h3 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
   }
    .cross-reference-body .container .search-tips-block .head .left h3 {
        font-size: 18px;
        line-height: 26px;
   }
    .cross-reference-body .container .search-tips-block .head .left img {
        max-width: 25px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .image {
        width: 100px;
        min-width: 100px;
        margin-bottom: 20px;
   }
    .cross-reference-body .container .search-results-blocks-wrapper .search-results-blocks .single-search-result .text-content .cta button {
        width: 100%;
        margin-bottom: 20px;
   }
    section.faqs-body .faqs-wrapper .faq-item .faq-item__question .question {
        font-size: 18px;
        line-height: 28px;
   }
    .single-product-body section.technical-info .container .single-table-notes__text {
        padding-left: 0;
   }
    section.faqs-body .cta .text {
        font-size: 20px;
        line-height: 30px;
   }
    .subscription-download-form .container .form-wrapper form .input-block:not(.full) {
        width: 100%;
   }
    .subscription-download-form .container .form-wrapper .close {
        top: 10px;
        right: 10px;
   }
    .product-breadcrumb .text {
        font-size: 14px;
        flex-wrap: wrap;
   }
    .single-product-body .navigation-sticky-container .navigation-wrapper .navigation {
        flex-wrap: wrap;
   }
    .single-product-body .navigation-sticky-container .navigation-wrapper .navigation .single-navigation {
        margin-bottom: 10px;
        padding-bottom: 5px;
   }
    .single-product-body .navigation-sticky-container .navigation-wrapper .navigation .single-navigation::before {
        height: 2px;
   }
    .single-product-body section.overview .container .product-image-zoom {
        top: 50px;
   }
    .single-product-body section.overview .container .overview-text {
        padding-right: 0;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block:not(:last-child) {
        margin-bottom: 4px;
   }
    .catalog-body .container .main-content .selected-filters-wrapper .sf-title {
        font-size: 18px;
        line-height: 26px;
   }
    .catalog-body .container .main-content .selected-filters .selected-filter {
        margin-bottom: 5px;
   }
    .catalog-body .container .main-content .selected-filters .selected-filter:not(:last-child) {
        margin-right: 5px;
   }
    .catalog-body .container .main-content .catalog-category-custom-margin .category-level-two .cl2-text, .catalog-body .container .main-content .subcategories-blocks .subcategory-block .body>.description {
        font-size: 14px;
        line-height: 24px;
   }
    .catalog-body .container .main-content .subcategories-blocks .subcategory-block .head h6 {
        font-size: 16px;
        line-height: 26px;
   }
    .catalog-body .container .main-content .selected-filters .selected-filter {
        padding: 3px;
   }
    .catalog-body .container .main-content .selected-filters .selected-filter span {
        margin-right: 7px;
   }
}
.news-body .container .main-content .news-wrapper .single-news-wrapper .single-news .image a{
    height: 300px;
}
@media (min-width: 2000px) {
    .navigation-sticky-container .navigation-wrapper .navigation, section.faqs-body .cta, .container, .search-block.fixed, .cross-reference-body .search-sticky-container.is-fixed h3, .search-sticky-container.is-fixed .search-block .search-container, section.faqs-body .faqs-wrapper {
        width: 80.5%;
        max-width: none;
   }
    h1 {
        font-size: 100px;
        line-height: 110px;
   }
    h2 {
        font-size: 70px;
        line-height: 80px;
   }
    h3 {
        font-size: 48px;
        line-height: 58px;
   }
    h4 {
        font-size: 44px;
        line-height: 54px;
   }
    h5 {
        font-size: 42px;
        line-height: 52px !important;
   }
    h6 {
        font-size: 38px;
        line-height: 48px;
   }
    section.entry-banner.hp .swiper .swiper-slide .text-content, section.products .container > h1 {
        max-width: 1300px;
   }
    section.entry-banner .container .text-content {
        max-width: 900px;
   }
    p {
        font-size: 22px;
        line-height: 30px;
   }
}
@media (min-width: 2500px) {
    .navigation-sticky-container .navigation-wrapper .navigation, section.faqs-body .cta, section.faqs-body .faqs-wrapper, .container, .search-block.fixed, .cross-reference-body .search-sticky-container.is-fixed h3, .search-sticky-container.is-fixed .search-block .search-container {
        width: 70.5%;
   }
    .catalog-body::before {
        width: 33%;
   }
}
/* animeted logo purple */
#e2H1wk6dUMV4 {
    animation: e2H1wk6dUMV4_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV4_c_o {
    0% {
        opacity: 0 
   }
    43.333333% {
        opacity: 0 
   }
    56.666667% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV5_to {
    animation: e2H1wk6dUMV5_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV5_to__to {
    0% {
        transform: translate(-16153.4px,-17517.63456px) 
   }
    63.333333% {
        transform: translate(-16153.4px,-17517.63456px) 
   }
    80% {
        transform: translate(-16153.4px,-17492px) 
   }
    100% {
        transform: translate(-16153.4px,-17492px) 
   }
}
#e2H1wk6dUMV5 {
    animation: e2H1wk6dUMV5_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV5_c_o {
    0% {
        opacity: 0 
   }
    63.333333% {
        opacity: 0 
   }
    80% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV6_to {
    animation: e2H1wk6dUMV6_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV6_to__to {
    0% {
        transform: translate(-16269px,-17468.02px) 
   }
    70% {
        transform: translate(-16269px,-17468.02px) 
   }
    83.333333% {
        transform: translate(-16269px,-17492px) 
   }
    100% {
        transform: translate(-16269px,-17492px) 
   }
}
#e2H1wk6dUMV6 {
    animation: e2H1wk6dUMV6_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV6_c_o {
    0% {
        opacity: 0 
   }
    70% {
        opacity: 0 
   }
    83.333333% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV7_to {
    animation: e2H1wk6dUMV7_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV7_to__to {
    0% {
        transform: translate(-16380.216639px,-17518.372px) 
   }
    73.333333% {
        transform: translate(-16380.216639px,-17518.372px) 
   }
    83.333333% {
        transform: translate(-16380.4px,-17490.5px) 
   }
    100% {
        transform: translate(-16380.4px,-17490.5px) 
   }
}
#e2H1wk6dUMV7 {
    animation: e2H1wk6dUMV7_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV7_c_o {
    0% {
        opacity: 0 
   }
    73.333333% {
        opacity: 0 
   }
    83.333333% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV8_to {
    animation: e2H1wk6dUMV8_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV8_to__to {
    0% {
        transform: translate(-16501.4px,-17468.0205px) 
   }
    76.666667% {
        transform: translate(-16501.4px,-17468.0205px) 
   }
    86.666667% {
        transform: translate(-16501.4px,-17492px) 
   }
    100% {
        transform: translate(-16501.4px,-17492px) 
   }
}
#e2H1wk6dUMV8 {
    animation: e2H1wk6dUMV8_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV8_c_o {
    0% {
        opacity: 0 
   }
    76.666667% {
        opacity: 0 
   }
    86.666667% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV9_to {
    animation: e2H1wk6dUMV9_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV9_to__to {
    0% {
        transform: translate(-16052.1px,-17471.856681px) 
   }
    53.333333% {
        transform: translate(-16052.1px,-17471.856681px) 
   }
    70% {
        transform: translate(-16052.1px,-17492px) 
   }
    100% {
        transform: translate(-16052.1px,-17492px) 
   }
}
#e2H1wk6dUMV9 {
    animation: e2H1wk6dUMV9_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV9_c_o {
    0% {
        opacity: 0 
   }
    53.333333% {
        opacity: 0 
   }
    70% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV10 {
    animation: e2H1wk6dUMV10_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV10_c_o {
    0% {
        opacity: 1 
   }
    40% {
        opacity: 1 
   }
    53.333333% {
        opacity: 0 
   }
    100% {
        opacity: 0 
   }
}
#e2H1wk6dUMV11_to {
    animation: e2H1wk6dUMV11_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV11_to__to {
    0% {
        transform: translate(13.668488px,32.463485px) 
   }
    13.333333% {
        transform: translate(13.668488px,32.463485px) 
   }
    46.666667% {
        transform: translate(13.668488px,31.463485px) 
   }
    100% {
        transform: translate(13.668488px,31.463485px) 
   }
}
#e2H1wk6dUMV11_tr {
    animation: e2H1wk6dUMV11_tr__tr 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV11_tr__tr {
    0% {
        transform: rotate(0deg) 
   }
    13.333333% {
        transform: rotate(0deg) 
   }
    46.666667% {
        transform: rotate(61.317929deg) 
   }
    100% {
        transform: rotate(61.317929deg) 
   }
}
#e2H1wk6dUMV14_to {
    animation: e2H1wk6dUMV14_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV14_to__to {
    0% {
        transform: translate(-312.86px,-350.935px) 
   }
    13.333333% {
        transform: translate(-312.86px,-350.935px) 
   }
    46.666667% {
        transform: translate(-310.926px,-358.743px) 
   }
    100% {
        transform: translate(-310.926px,-358.743px) 
   }
}
/* animated logo dark */
#e2H1wk6dUMV4 {
    animation: e2H1wk6dUMV4_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV4_c_o {
    0% {
        opacity: 0 
   }
    43.333333% {
        opacity: 0 
   }
    56.666667% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV5_to {
    animation: e2H1wk6dUMV5_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV5_to__to {
    0% {
        transform: translate(-16153.4px,-17517.63456px) 
   }
    63.333333% {
        transform: translate(-16153.4px,-17517.63456px) 
   }
    80% {
        transform: translate(-16153.4px,-17492px) 
   }
    100% {
        transform: translate(-16153.4px,-17492px) 
   }
}
#e2H1wk6dUMV5 {
    animation: e2H1wk6dUMV5_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV5_c_o {
    0% {
        opacity: 0 
   }
    63.333333% {
        opacity: 0 
   }
    80% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV6_to {
    animation: e2H1wk6dUMV6_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV6_to__to {
    0% {
        transform: translate(-16269px,-17468.02px) 
   }
    70% {
        transform: translate(-16269px,-17468.02px) 
   }
    83.333333% {
        transform: translate(-16269px,-17492px) 
   }
    100% {
        transform: translate(-16269px,-17492px) 
   }
}
#e2H1wk6dUMV6 {
    animation: e2H1wk6dUMV6_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV6_c_o {
    0% {
        opacity: 0 
   }
    70% {
        opacity: 0 
   }
    83.333333% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV7_to {
    animation: e2H1wk6dUMV7_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV7_to__to {
    0% {
        transform: translate(-16380.216639px,-17518.372px) 
   }
    73.333333% {
        transform: translate(-16380.216639px,-17518.372px) 
   }
    83.333333% {
        transform: translate(-16380.4px,-17490.5px) 
   }
    100% {
        transform: translate(-16380.4px,-17490.5px) 
   }
}
#e2H1wk6dUMV7 {
    animation: e2H1wk6dUMV7_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV7_c_o {
    0% {
        opacity: 0 
   }
    73.333333% {
        opacity: 0 
   }
    83.333333% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV8_to {
    animation: e2H1wk6dUMV8_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV8_to__to {
    0% {
        transform: translate(-16501.4px,-17468.0205px) 
   }
    76.666667% {
        transform: translate(-16501.4px,-17468.0205px) 
   }
    86.666667% {
        transform: translate(-16501.4px,-17492px) 
   }
    100% {
        transform: translate(-16501.4px,-17492px) 
   }
}
#e2H1wk6dUMV8 {
    animation: e2H1wk6dUMV8_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV8_c_o {
    0% {
        opacity: 0 
   }
    76.666667% {
        opacity: 0 
   }
    86.666667% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV9_to {
    animation: e2H1wk6dUMV9_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV9_to__to {
    0% {
        transform: translate(-16052.1px,-17471.856681px) 
   }
    53.333333% {
        transform: translate(-16052.1px,-17471.856681px) 
   }
    70% {
        transform: translate(-16052.1px,-17492px) 
   }
    100% {
        transform: translate(-16052.1px,-17492px) 
   }
}
#e2H1wk6dUMV9 {
    animation: e2H1wk6dUMV9_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV9_c_o {
    0% {
        opacity: 0 
   }
    53.333333% {
        opacity: 0 
   }
    70% {
        opacity: 1 
   }
    100% {
        opacity: 1 
   }
}
#e2H1wk6dUMV10 {
    animation: e2H1wk6dUMV10_c_o 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV10_c_o {
    0% {
        opacity: 1 
   }
    40% {
        opacity: 1 
   }
    53.333333% {
        opacity: 0 
   }
    100% {
        opacity: 0 
   }
}
#e2H1wk6dUMV11_to {
    animation: e2H1wk6dUMV11_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV11_to__to {
    0% {
        transform: translate(13.668488px,32.463485px) 
   }
    13.333333% {
        transform: translate(13.668488px,32.463485px) 
   }
    46.666667% {
        transform: translate(13.668488px,31.463485px) 
   }
    100% {
        transform: translate(13.668488px,31.463485px) 
   }
}
#e2H1wk6dUMV11_tr {
    animation: e2H1wk6dUMV11_tr__tr 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV11_tr__tr {
    0% {
        transform: rotate(0deg) 
   }
    13.333333% {
        transform: rotate(0deg) 
   }
    46.666667% {
        transform: rotate(61.317929deg) 
   }
    100% {
        transform: rotate(61.317929deg) 
   }
}
#e2H1wk6dUMV14_to {
    animation: e2H1wk6dUMV14_to__to 3000ms linear 1 normal forwards 
}
@keyframes e2H1wk6dUMV14_to__to {
    0% {
        transform: translate(-312.86px,-350.935px) 
   }
    13.333333% {
        transform: translate(-312.86px,-350.935px) 
   }
    46.666667% {
        transform: translate(-310.926px,-358.743px) 
   }
    100% {
        transform: translate(-310.926px,-358.743px) 
   }
}
/* CATALOG SPINNER STYLE */
.loader {
    position: absolute;
    top: 50%;
    left: 65%;
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #0A1E5B;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
   }
    100% {
        transform: rotate(360deg);
   }
}
/* CONTACT MESSAGE SUCCESS */
.right .message-wrapper {
    width: 100%;
    height: 100%;
    padding: 61px;
    border-radius: 10px;
    background-color: #0A1E5B;
    display: flex;
    justify-content: center;
    align-items: center;
}
.success-message {
    opacity: 0;
    transform: translateY(-40px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}
.success-message.active {
    opacity: 1;
    transform: translateY(0);
}
